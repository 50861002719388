<template>
  <form class="sign-up-form">
    <div class="input-group-lg">
      <ValidationObserver
        tag="form"
        @submit.prevent="updateUser()"
        ref="userEdit"
      >
        <form-field
          :formFields="fields"
          :show="true"
          :model="data.updateUser"
          :id-prefix="idPrefix"
        />

        <div class="form-check col-12">
          <input
            class="form-check-input" type="checkbox"
            v-model="data.updateUser.subscribed"
            :class="settings.marketing.class"
            id="user-subscribed"
            :disabled="disable_edit_profile"
          >
          <label class="form-check-label" for="user-subscribed">
            {{ settings.marketing.text }}
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3"
          :class="settings.attributes.button.class"
          v-if="!disable_edit_profile"
        >
          {{ settings.attributes.button.text }}
        </button>

        <button
          type="button"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3"
          :class="settings.attributes.button2.class"
          @click="redirectBack(settings.attributes.button2['path-name'])"
          v-else
        >
          {{ settings.attributes.button2.text }}
        </button>
      </ValidationObserver>
    </div>
  </form>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from './form/FormField.vue';
import router from '../router/index';

export default {
  name: 'UserFormFields',

  components: {
    FormField,
    ValidationObserver,
  },

  data() {
    return {
      idPrefix: 'user-edit-page',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertMsg: '',

      data: {
        updateUser: {

        },
      },
    };
  },

  mounted() {
    this.updateUserData();
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },
    disable_edit_profile() {
      return this.partner.settings.disable_user_profile_edition;
    },
    fields() {
      if (this.disable_edit_profile) {
        return this.settings.fields.map((field) => (
          {
            ...field,
            disabled: 'true',
          }
        ));
      }
      return this.settings.fields;
    },
  },

  methods: {
    updateUser() {
      let userdata = this.data.updateUser;
      if (this.disable_edit_profile) {
        userdata = {
          subscribed: this.data.updateUser.subscribed,
          extra_info: this.data.updateUser.extra_info,
        };
      }

      this.$refs.userEdit.validate()
        .then((isValid) => {
          if (isValid) {
            this.$store.dispatch('users/put', userdata)
              .then(() => {
                this.$notify({
                  group: 'notice',
                  text: 'Profile updated.',
                });
                this.$emit('update-success');
              })
              .catch(() => {
                this.$notify({
                  group: 'notice',
                  text: 'Unable to proceed. Please try again.',
                });
              });
          } else {
            this.$notify({
              group: 'notice',
              text: 'Fill in all required fields.',
            });
          }
        });
    },

    updateUserData() {
      this.data.updateUser = {
        ...this.$store.state.users.currentUser,
        ...this.$store.state.users.currentUser.extra_info,
      };
    },

    redirectBack(name) {
      router.push({ name });
    },
  },
};
</script>
