<template>
  <div id="application-wrapper" :style="customCss" v-if="this.customCss">
    <notifications
      group="notice2"
      position="top left"
      width="100%"
      :ignoreDuplicates=true
      :closeOnClick=false
      class="notification2 position-relative"
      :duration=-1
    />

    <notifications
      group="notice"
      position="top left"
      width="100%"
      :ignoreDuplicates=true
      class="notification"
      :duration=5000
    />

    <component :is="layout" />
  </div>
</template>

<script>

import GoogleTagManager from '@/shared/mixins/googleTagManager';
import Localize from './shared/mixins/localize';
import ApplicationLayout from './layouts/ApplicationLayout.vue';
import ajax from './ajax';

export default {
  name: 'app',

  components: {
    'application-layout': ApplicationLayout,
  },

  mounted() {
    this.createVisit();
  },

  data() {
    return {};
  },

  computed: {
    layout() {
      return 'application-layout';
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    user() {
      return this.$store.state.users.currentUser;
    },
  },

  created() {
    // do this only once
    this.$store.dispatch('partners/get').then(() => {
      console.log('Partner settings loaded!!');
      this.initializeLocalizeTag();
      this.initializeGoogleTagManager();
      this.updateFavicon();

      // Add customCss to body tag when loaded
      if (this.customCss) {
        document.body.style = Object.entries(this.customCss).map(([k, v]) => `${k}:${v}`).join(';');
      }

      const { error } = this.$route.query;
      if (error) {
        this.$notify({
          group: 'notice',
          text: error,
        });
      }
    });
  },

  methods: {
    createVisit() {
      return ajax.post('/ahoy/visits', {
        landing_page: window.location.href,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        referrer: document.referrer || null,
        user_id: this.user.id || null,
      }, {
        headers: { 'Content-type': 'application/json' },
      }).catch((error) => {
        console.log(error);
      });
    },

    updateFavicon() {
      const favicon = document.getElementById('va-favicon');
      if (favicon && this.partner.settings.favicon_url) {
        console.log(this.partner.settings.favicon_url);
        favicon.href = this.partner.settings.favicon_url;
      }
    },
  },
  mixins: [Localize, GoogleTagManager],
};
</script>

<style lang="scss">
@import 'assets/stylesheets/application.scss';
</style>
