<template>
  <div class="range-slider d-flex mt-4 justify-content-center">
    <div class="slider-box p-3">
      <span class="d-sm-none range-slider-label">
        {{ this.field.label }}
      </span>
      <div class="row">
        <div class="col-12 col-md-3 m-auto">
          <h5 class="d-none d-sm-flex range-slider-label">
            {{ this.field.label }}
          </h5>
          <span class="text-black-50 d-none d-sm-flex" v-if="this.field.description">
            <small>
              {{ this.field.description }}
            </small>
          </span>
        </div>

        <b-form-input
          class="d-flex justify-content-center ml-3 mt-auto mb-auto m-md-auto col-7 col-md-5"
          type="range"
          v-model="fieldValue"
          :min="this.field.min"
          :max="this.field.max"
          :step="this.field.step"
        />

        <div class="range-value col-4 col-md-3 m-auto d-flex justify-content-center">
          <div>
            <div
              class="d-flex justify-content-end"
              :class="[tooltip ? '' : ['mb-3', 'mt-2'] ]"
            >
              <span class="text-black-50" notranslate>{{ fieldValue }}</span>
              <span
                class="text-black-50 pl-1 m-auto d-none d-sm-block"
                v-if="field.value_text"
              >
                {{ field.value_text }}
              </span>
            </div>
            <div v-if="tooltip">
            <span
              :id="`${this.field.name}-tooltip`"
              class="font-italic small egx-partner-color d-flex justify-content-end"
            >
              {{ tooltip.text }}
            </span>
              <b-tooltip
                :target="`${this.field.name}-tooltip`"
                triggers="hover"
                :placement="'auto'"
              >
                {{ tooltip.body }}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    field: Object,
    sectionTitle: String,
  },

  computed: {
    currentGenericItem() {
      return this.$store.state.generic_items.currentGenericItems[this.sectionTitle];
    },

    tooltip() {
      return this.field.tooltip ? this.field.tooltip : false;
    },

    fieldValue: {
      get() {
        if (this.currentGenericItem.data[this.field.name][0] === undefined) {
          this.updateRangeValue(this.field.default || this.field.min);
          return (this.field.default || this.field.min);
        }
        return this.currentGenericItem.data[this.field.name][0];
      },
      set(newValue) {
        this.updateRangeValue(newValue);
      },
    },
  },

  methods: {
    updateRangeValue(value) {
      const currentGenericItemData = this.currentGenericItem;
      currentGenericItemData.data[this.field.name] = [value];

      const obj = { key: this.sectionTitle, value: currentGenericItemData };

      this.$store.commit('generic_items/setSectionData', obj);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/range-slider.scss";
</style>
