<template>
  <div class="container home-overview">
    <loading-screen v-if="isLoading" :content="loadingScreenContent" />
    <error-screen v-else-if="virtualAuditError" :content="errorScreenContent" />

    <div v-else class="pb-5 mt-5">
      <h1
        class="text-center egx-partner-color"
        :style="this.homeOverview.title.style"
        :class="this.homeOverview.title.class"
      >
        {{ this.homeOverview.title.text }}
      </h1>
      <h5
        class=
          "p-4
           mb-4
           text-center
           d-flex
           justify-content-center
           home-page-body-text"
        :style="this.homeOverview.description.style"
        :class="this.homeOverview.description.class"
      >
        {{ this.homeOverview.description.text }}
      </h5>

      <div v-html="this.homeOverview.below_description_html" />

      <HomeEnergyReportButton
        :settings="herSettings" v-if="herSettings.enable && herSettings.display_top"
        :building-id="this.building.id"
      />

      <router-link
        v-if="this.homeOverview.return_to_audit_cta"
        :to="{ name: this.homeOverview.return_to_audit_cta.path_name }"
      >
        <b-button
          class="d-flex ml-auto mr-auto mt-5 p-2 btn-egx-white w-100 justify-content-center mb-5"
          :class="this.homeOverview.return_to_audit_cta.class"
          :style="this.homeOverview.return_to_audit_cta.style"
        >
          {{ this.homeOverview.return_to_audit_cta.text }}
        </b-button>
      </router-link>


      <div>
        <div>
          <b-card-group deck class="home-overview-cards m-auto">
            <b-card class="home-overview-card">
              <h5 class="text-black-50 mb-3">{{ profileCardTitle }}</h5>
              <div class="mb-3 flex-column d-flex" v-if="this.building.construction_year">
                <span class="home-overview-card__label">{{ cardLabel('year_built') }}</span>
                <span class="thicker-font-weight home-overview-card__value" notranslate>
                  {{ this.building.construction_year }}
                </span>
              </div>
              <div class="mb-3 flex-column d-flex">
                <span class="home-overview-card__label">{{ cardLabel('location') }}</span>
                <span
                  class="thicker-font-weight text-capitalize home-overview-card__value"
                  notranslate
                >
                  {{ getBuildingAddress(this.building, this.addressElements).replace(/,/g, '') }}
                </span>
              </div>
              <div class="mb-3 flex-column d-flex">
                <span class="home-overview-card__label">{{ cardLabel('floor_area') }}</span>
                <span class="thicker-font-weight home-overview-card__value">
                  <var floor-area>{{ this.building.floor_area }}</var> {{ this.area_unit() }}
                </span>
              </div>
              <div class="mb-3 flex-column d-flex" v-if="electricUtility">
                <span class="home-overview-card__label">{{ cardLabel('electric_utility') }}</span>
                <span class="thicker-font-weight home-overview-card__value">
                  {{ electricUtility | capitalize }}
                </span>
              </div>
              <div class="mb-3 flex-column d-flex" v-if="gasUtility">
                <span class="home-overview-card__label">{{ cardLabel('gas_utility') }}</span>
                <span class="thicker-font-weight home-overview-card__value">
                  {{ gasUtility | capitalize }}
                </span>
              </div>
            </b-card>

            <b-card class="home-overview-card">
              <h5 class="text-black-50 mb-3">{{ cardTitle }}</h5>
              <template v-if="!hideEnergySavings && !showChart">
                <div class="flex-column d-flex">
                  <span class="home-overview-card__label">{{ cardLabel('current') }}</span>
                  <span class="thicker-font-weight home-overview-card__value">
                    <var current-consumption-value>
                      {{ wholeNumber(this.consumptionBeforeRetrofit.value) }}
                      {{ this.consumptionBeforeRetrofit.unit }}
                    </var> per year
                    <sup
                      class="consumption-sup"
                      v-if="this.usageAndRating && this.usageAndRating.current.tooltip"
                      id="usage-and-rating-current-tooltip"
                    >
                      <font-awesome-icon :icon="['fa', 'question']" class="consumption-tooltip" />
                    </sup>
                  </span>
                  <span
                    class="thicker-font-weight home-overview-card__value"
                    v-if="consumptionBeforeKgCO2 !== 0 && enableKGCO2Predictions"
                  >
                    <var current-consumption-value-kgco2>
                      {{ wholeNumber(consumptionBeforeKgCO2) }}
                      {{ kgCO2Label }}
                    </var> per year
                  </span>
                  <b-tooltip
                    target="usage-and-rating-current-tooltip"
                    custom-class="partner-tooltip"
                    triggers="hover"
                    v-if="this.usageAndRating && this.usageAndRating.current.tooltip"
                  >
                    {{ this.usageAndRating.current.tooltip.text }}
                  </b-tooltip>
                </div>
                <div class="mt-4 mb-4">
                  <hr>
                </div>
                <div class="d-flex flex-column">
                  <span class="home-overview-card__label">{{ cardLabel('predicted') }}</span>
                  <span class="thicker-font-weight home-overview-card__value">
                    <var potential-consumption-value>
                      {{ wholeNumber(this.consumptionAfterRetrofit.value) }}
                      {{ this.consumptionAfterRetrofit.unit }}
                    </var> per year
                    <sup
                      class="consumption-sup"
                      v-if="this.usageAndRating && this.usageAndRating.predicted.tooltip"
                      id="usage-and-rating-predicted-tooltip"
                    >
                      <font-awesome-icon :icon="['fa', 'question']" class="consumption-tooltip" />
                    </sup>
                  </span>
                  <span
                    class="thicker-font-weight home-overview-card__value"
                    v-if="consumptionAfterKgCO2 !== 0 && enableKGCO2Predictions"
                  >
                    <var potential-consumption-value-kgco2>
                      {{ wholeNumber(consumptionAfterKgCO2) }}
                      {{ kgCO2Label }}
                    </var> per year
                  </span>
                  <b-tooltip
                    target="usage-and-rating-predicted-tooltip"
                    custom-class="partner-tooltip"
                    triggers="hover"
                    v-if="this.usageAndRating && this.usageAndRating.predicted.tooltip"
                  >
                    {{ this.usageAndRating.predicted.tooltip.text }}
                  </b-tooltip>
                </div>
              </template>
              <div class="d-flex flex-column mt-4">
                <span class="home-overview-card__label">{{ cardLabel('energy_savings') }}</span>
                <span class="thicker-font-weight home-overview-card__value">
                  <var savings-value>
                    {{ currencyIcon }}
                    {{ formatNumber(
                        showChart ? sumAllMoneyMeasurement : this.calculateEnergySavings('money')
                      )
                    }}
                  </var> per year
                  <sup
                    class="consumption-sup"
                    v-if="this.usageAndRating && this.usageAndRating.energy_savings.tooltip"
                    id="usage-and-rating-energy-savings-tooltip"
                  >
                    <font-awesome-icon :icon="['fa', 'question']" class="consumption-tooltip" />
                  </sup>
                </span>
                <b-tooltip
                  target="usage-and-rating-energy-savings-tooltip"
                  custom-class="partner-tooltip"
                  triggers="hover"
                  v-if="this.usageAndRating && this.usageAndRating.energy_savings.tooltip"
                >
                  {{ this.usageAndRating.energy_savings.tooltip.text }}
                </b-tooltip>
              </div>
              <template v-if="showChart">
                <BarCharts :chartData="chartData" :moneyType="currencyIcon" :config="chartConfig" />
              </template>
              <div class="mt-4 mb-4" v-if="objectAttr(cardLabels, 'available_rebates')">
                <hr>
              </div>
              <div
                class="d-flex flex-column mt-4" v-if="objectAttr(cardLabels, 'available_rebates')"
              >
                <span class="home-overview-card__label">{{ cardLabel('available_rebates') }}</span>
                <span class="thicker-font-weight home-overview-card__value">
                  <var savings-value>
                    {{ cardLabel('available_rebates_value') }}
                  </var>
                </span>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>

      <div v-if="homeOverview.single_cta.enable">
        <incentive-contact :currentIncentive="singleCtaIncentive" />
        <b-button
          class="d-flex ml-auto mr-auto mt-5 w-100 justify-content-center btn-single-cta"
          :style="homeOverview.single_cta.style"
          @click="singleCtaBtnOnClick()"
        >
          {{ singleCtaBtnText }}
        </b-button>
      </div>

      <router-link :to="{ name: homeOverview.home_analysis_cta.path_name }">
        <b-button
          class="d-flex ml-auto mr-auto p-2 btn-egx-white w-100 justify-content-center mb-5 mt-5"
        >
          {{ homeOverview.home_analysis_cta.text }}
        </b-button>
      </router-link>

      <h5
        v-if="homeOverview.text_module"
        class=
          "p-2
           my-4
           text-center
           d-flex
           justify-content-center
           home-page-body-text"
        :style="homeOverview.text_module.style"
        :class="homeOverview.text_module.class"
        v-html="homeOverview.text_module.text"
      />

      <h2
        class="text-center egx-partner-color mt-5"
        :style="this.homeOverview.sub_sections.recommendation_section.title.style"
        :class="this.homeOverview.sub_sections.recommendation_section.title.class"
      >
        {{ this.homeOverview.sub_sections.recommendation_section.title.text }}
      </h2>

      <h5
        class=
          "p-2
           mb-4
           text-center
           d-flex
           justify-content-center
           home-page-body-text"
        :style="this.homeOverview.sub_sections.recommendation_section.description.style"
        :class="this.homeOverview.sub_sections.recommendation_section.description.class"
        v-html="this.homeOverview.sub_sections.recommendation_section.description.text"
      />

      <div v-if="recommendedCategories && recommendedCategories.all_filters">
        <div class="section-form-box">
          <h5 class="category-filters__title">Filter</h5>
          <div class="category-filters">
            <div
              v-for="(category, key) in recommendedCategories.all_filters"
              :key="key"
              @change="incentiveCategory(key)"
              class="category-filter"
            >
              <input type="radio" :value="key" :id="key" v-model="incentiveCategoryKey">
              <label for="key" class="p-2 category-filters__label">{{ category.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(incentiveSection, index) in recommendedIncentives"
        :key="incentiveSection.name"
        class="incentives"
      >
        <div v-if="incentiveSection.length > 0">
          <incentives-section
            :incentivesArray="incentiveSection"
            :incentivesType="'available'"
            :tagStoreName="'allIncentiveTags'"
            :sectionSettings="{ title: humanize(index), titleKey: index }"
            :virtualAuditSavings="virtualAuditSavings(index)"
            :show-filter="false"
            :showIncentiveSavings="true"
            :enableSingleCta="homeOverview.single_cta.enable"
            :isOverview=true
            class="m-auto"
            :currencyIcon="currencyIcon"
          />
        </div>
      </div>

      <router-link
        :to=
          "{ name: this.homeOverview.sub_sections.recommendation_section.incentives_cta.path_name }"
        v-if="homeOverview.sub_sections.recommendation_section.incentives_cta.text"
      >
        <span
          class="d-flex justify-content-center egx-partner-color mt-5 info-span"
          :class="this.homeOverview.sub_sections.recommendation_section.incentives_cta.class"
          :style="this.homeOverview.sub_sections.recommendation_section.incentives_cta.style"
        >
          <font-awesome-icon class="mt-auto mb-auto ml-2 mr-2" :icon="['fa', 'arrow-down']" />
          <u>{{ this.homeOverview.sub_sections.recommendation_section.incentives_cta.text }}</u>
          <font-awesome-icon class="mt-auto mb-auto ml-2 mr-2" :icon="['fa', 'arrow-down']" />
        </span>
      </router-link>

      <h5
        v-if="homeOverview.text_module2"
        class=
          "p-2
           my-4
           text-center
           d-flex
           justify-content-center
           home-page-body-text"
        :style="homeOverview.text_module2.style"
        :class="homeOverview.text_module2.class"
        v-html="homeOverview.text_module2.text"
      />

      <div v-if="homeOverview.single_cta.enable">
        <incentive-contact :currentIncentive="singleCtaIncentive" />
        <b-button
          class="d-flex ml-auto mr-auto mt-3 w-100 justify-content-center btn-single-cta"
          :style="homeOverview.single_cta.style"
          @click="singleCtaBtnOnClick()"
        >
          {{ singleCtaBtnText }}
        </b-button>
      </div>

      <router-link :to="{ name: homeOverview.home_analysis_cta.path_name }">
        <b-button
          class="d-flex ml-auto mr-auto mt-4 p-2 btn-egx-white w-100 justify-content-center"
        >
          {{ homeOverview.home_analysis_cta.text }}
        </b-button>
      </router-link>

      <span
        class=
          "d-flex
           justify-content-center
           mt-5
           text-center
           mr-5 ml-5
           info-span
           home-page-body-text"
        :class="this.homeOverview.sub_sections.energy_savings_section.description.class"
        :style="this.homeOverview.sub_sections.energy_savings_section.description.style"
        v-if="!zeroSavings"
        v-html="this.energySavingsSectionDescription()"
      />

      <div class="d-flex justify-content-center mt-4">
        <div v-for="(icons, index) in this.homeOverview.icons" :key="`icon-${index}`">
          <div
            class="bottom-icons m-1 m-md-3"
            :style="icons.container.style"
            :class="icons.container.class"
          >
            <font-awesome-icon
              class="icon"
              :style="icons.container.icon.style"
              :class="icons.container.icon.class"
              :icon="icons.container.icon.type"
            />
          </div>
        </div>
      </div>

      <HomeEnergyReportButton
        :settings="herSettings" v-if="herSettings.enable && !herSettings.display_top"
        :building-id="this.building.id"
      />
    </div>

  </div>
</template>

<script>
import numeral from 'numeral';
import ErrorScreen from '@/components/ErrorScreen.vue';
import HomeEnergyReportButton from '@/components/HomeEnergyReportButton.vue';
import IncentivesSection from '../components/IncentivesSection.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import BuildingAddressMixin from '../shared/mixins/building-address';
import IncentiveContact from '../components/IncentiveContact.vue';
import Numeric from '../shared/mixins/numeric';
import objectAttr from '../shared/mixins/object-attribute';
import BarCharts from '../components/BarCharts.vue';

export default {
  name: 'HomeOverview',

  components: {
    HomeEnergyReportButton,
    ErrorScreen,
    IncentivesSection,
    LoadingScreen,
    IncentiveContact,
    BarCharts,
  },

  mixins: [
    BuildingAddressMixin,
    Numeric,
    objectAttr,
  ],

  data() {
    return {
      isLoading: false,
      virtualAuditError: false,
      addressElements: ['address1', 'address2', 'city', 'community', 'region', 'zip'],

      incentiveCategoryKey: '',
    };
  },

  created() {
    if (this.recommendedCategories && this.recommendedCategories.default_filter !== '') {
      this.incentiveCategoryKey = this.recommendedCategories.default_filter;
    }
  },

  mounted() {
    this.isLoading = true;
    const storeType = this.useOffer ? 'offers/getAll' : 'subsidies/getAll';

    this.fetchBuilding()
      .then(() => {
        this.$store.dispatch('virtual_audits/post', this.building.id)
          .then(async () => {
            await this.$store.dispatch('buildings/set', this.building.id);
          })
          .then(async () => {
            await this.$store.dispatch('buildings/getAll');
            await this.$store.dispatch(storeType);
          })
          .then(() => {
            this.isLoading = false; // show page once loaded
          })
          .catch((err) => {
            this.isLoading = false;
            this.virtualAuditError = true;
            console.error(err);
          });
      })
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'buildings-list' });
      });
  },

  computed: {
    useOffer() {
      return this.partner.settings.energyxpert_admin_enabled;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    building() {
      return this.$store.state.buildings.currentBuilding;
    },

    section() {
      return this.partner.settings.sections.audit_sections[this.type][this.type];
    },

    genericItems() {
      return this.$store.state.generic_items.currentGenericItems;
    },

    virtualAuditResults() {
      return this.$store.state.virtual_audits.currentVirtualAudit;
    },

    recommendedIncentives() {
      // TODO: The method name should be changed later to recommendedOffers
      return this.$store.getters['buildings/filterOutSoftRecommendation'](this.incentiveCategoryKey);
    },

    consumptionBeforeRetrofit() {
      return this.virtualAuditResults.consumptions.current.total_consumption_before_retrofit;
    },

    consumptionAfterRetrofit() {
      return this.virtualAuditResults.consumptions.new.total_consumption_after_retrofit;
    },

    consumptionBeforeKgCO2() {
      return this.virtualAuditResults.total_kg_co2.current;
    },

    consumptionAfterKgCO2() {
      return this.virtualAuditResults.total_kg_co2.new;
    },

    homeOverview() {
      return this.$store.state
        .partners.currentPartner.settings.sections.home_overview_page.home_overview;
    },

    loadingScreenContent() {
      return this.homeOverview.configurations.loading_screen_configurations;
    },

    errorScreenContent() {
      return this.partner.settings.sections.error_page;
    },

    singleCtaIncentive() {
      // TODO: This need to be done for all the other partners that is using single cta
      // not being used by Yukon
      const incentives = this.$store.state.subsidies.allSubsidies;
      return incentives.find(
        (incentive) => incentive.id === this.homeOverview.single_cta.subsidy_id,
      );
    },

    eaAssignment() {
      return this.building.ea_assignment;
    },

    singleCtaBtnText() {
      const projectTypeId = this.singleCtaIncentive
        ? this.singleCtaIncentive.attributes.project_type.id : null;

      if (projectTypeId && this.eaAssignment[projectTypeId]) {
        return this.homeOverview.single_cta.contacted;
      }
      return this.homeOverview.single_cta.contact;
    },

    recommendedCategories() {
      return this.partner.tags.recommended_categories;
    },

    usageAndRating() {
      return this.homeOverview.sub_sections.usage_and_rating;
    },

    zeroSavings() {
      return this.calculatePercentageDifference(
        this.consumptionBeforeRetrofit.value,
        this.consumptionAfterRetrofit.value,
      ) === 0;
    },

    cardLabels() {
      return this.homeOverview.card_labels;
    },

    profileCardTitle() {
      return this.objectAttr(this.cardLabels, 'home_profile_title') || 'Your Home Profile';
    },

    cardTitle() {
      return this.objectAttr(this.cardLabels, 'title') || 'Energy usage';
    },

    currencyIcon() {
      return this.partner.currency_icon || '$';
    },

    hideEnergySavings() {
      return this.partner.settings.hide_energy_savings_in_reports;
    },

    enableKGCO2Predictions() {
      return this.partner.settings.enable_kgco2_home_overview;
    },

    kgCO2Label() {
      return 'kg CO2';
    },

    electricUtility() {
      return this.building.extra_info.e_community?.split('_')?.join(' ');
    },

    gasUtility() {
      return this.building.extra_info.ng_community?.split('_')?.join(' ');
    },

    herSettings() {
      return this.homeOverview.home_energy_report_cta || {};
    },

    showChart() {
      return this.partner.settings.enable_chart_home_overview;
    },

    chartSettings() {
      return this.partner.settings.sections.chart_settings.home_overview_energy_money_savings;
    },

    sumAllMoneyMeasurement() {
      return this.virtualAuditResults.savings.all_measurements?.money?.total || 0;
    },

    chartData() {
      const allMeasurementsMoney = this.virtualAuditResults.savings.all_measurements.money;
      const convertedArray = this.objectToArray(allMeasurementsMoney);
      return {
        labels: convertedArray.map((row) => row.key),
        datasets: [{
          label: this.chartSettings.title.text || '',
          data: convertedArray.map((row) => row.value.toFixed(0)),
          backgroundColor: this.chartSettings.chart.style.background_color || [],
          datalabels: {
            formatter: (value) => (`${this.currencyIcon} ${value}`),
          },
        }],
      };
    },
    chartConfig() {
      return {
        y_axes_begin_at_zero: this.chartSettings.configurations.y_axes_begin_at_zero,
        y_axes_max_ticks_limit: this.chartSettings.configurations.y_axes_max_ticks_limit,
        datalable_color: this.chartSettings.chart.style.color || 'black',
        labels_color: this.chartSettings.chart_x.style.color,
        labels_font_size: this.chartSettings.chart_x.style.font_size,
        class: this.chartSettings.chart.class,
      };
    },
  },

  methods: {
    calculateEnergySavings(type) {
      const resultsObject = this.virtualAuditResults.savings[type];
      let total = 0;

      Object.keys(resultsObject).forEach((key) => {
        total += resultsObject[key].value;
      });

      return total;
    },

    virtualAuditSavings(incentiveKey) {
      let money = 0;
      let ghg = 0;

      if (this.virtualAuditResults.savings.money[incentiveKey]) {
        money = this.virtualAuditResults.savings.money[incentiveKey].value || 0;
      }

      if (this.virtualAuditResults.savings.ghg[incentiveKey]) {
        ghg = this.virtualAuditResults.savings.ghg[incentiveKey].value || 0;
      }

      const virtualAuditSavings = {
        money,
        ghg,
      };

      return virtualAuditSavings;
    },

    humanize(name) {
      const mappedName = this.mapCategoryName(name);
      if (name === mappedName) {
        // No mapping found, humanize the key
        const words = name.match(/[A-Za-z][a-z]*/g) || [];
        return this.capitalize(words.join(' '));
      }

      return mappedName;
    },

    mapCategoryName(key) {
      // Humanize the category name
      const mappings = this.homeOverview.sub_sections.recommendation_section
        .incentive_category_names;

      if (mappings && mappings[key] !== undefined) return mappings[key];

      return key;
    },

    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    },

    calculatePercentageDifference(number1, number2) {
      return Math.round((((number1 - number2) / number1) * 100));
    },

    formatNumber(value) {
      return numeral(value).format('0,0');
    },

    incentiveCategory(categoryKey) {
      this.incentiveCategoryKey = categoryKey;
    },

    singleCtaBtnOnClick() {
      if (this.homeOverview.single_cta.url) {
        window.open(this.homeOverview.single_cta.url, '_blank');
      } else {
        this.$bvModal.show(`incentive-contact-${this.homeOverview.single_cta.subsidy_id}`);
      }
    },

    energySavingsSectionDescription() {
      const description = `
        <span>${this.homeOverview.sub_sections.energy_savings_section.description.text}</span>
      `;
      const currentDifference = this.calculatePercentageDifference(
        this.consumptionBeforeRetrofit.value,
        this.consumptionAfterRetrofit.value,
      ) || '0';
      const newValue = `<var savings-percentage>${currentDifference.toString().concat('%')}</var>`;

      return description.replace('{{%}}', newValue.toString()).toString();
    },

    cardLabel(label) {
      return this.objectAttr(this.cardLabels, label) || this.humanize(label);
    },

    area_unit() {
      return this.partner.settings.area_basics_in_meters ? 'square meters' : 'square feet';
    },

    fetchBuilding() {
      return new Promise((resolve, reject) => {
        // Set the building based on passed query buildingId if present
        if (this.$route.query.buildingId) {
          this.$store.dispatch('buildings/set', this.$route.query.buildingId)
            .then(() => {
              console.log('BUILDING: ', this.building);
              resolve(this.building);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (this.building?.id === null || this.building?.id === undefined) {
          reject(new Error('Could not find building'));
        } else {
          resolve();
        }
      });
    },
    objectToArray(data) {
      // To test with static data uncomment the const after this line, remove the parameter from the
      // above function and the chartData method call

      // const data = {
      //   electricity: 356.54,
      //   natural_gas: 0,
      //   natural_gas2: -574.98,
      //   natural_gas3: 574.98,
      //   natural_gas4: -674.98,
      //   total: 931.52,
      // };
      return Object.entries(data)
        .filter(([key, value]) => key !== 'total' && value !== 0)
        .map(([key, value]) => ({ key: key.replace('_', ' '), value }));
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/pages/home_overview.scss';
@import '../assets/stylesheets/pages/incentives';
</style>
