import capitalize from '../filters/capitalize';
import zipFormatter from './zip-formatter';

export default {
  mixins: [zipFormatter],

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },
  },

  methods: {
    // example: addressElements = ['address1', 'address2', 'zip', 'city'];
    getBuildingAddress(building, addressElements) {
      let newArr = addressElements;

      if (!this.partner.settings.show_city) {
        newArr = addressElements.filter((item) => item !== 'city');
      }

      if (!this.partner.settings.show_community) {
        newArr = addressElements.filter((item) => item !== 'community');
      }

      let addressString = '';

      newArr.forEach((element) => {
        if (building[element] || building.extra_info[element]) {
          let addressElement = building[element] || building.extra_info[element];

          if (addressElement !== '') {
            if (element === 'zip') {
              addressElement = zipFormatter.methods.formatZip(addressElement, building.country);
            }
            addressString = addressString.concat(` ${addressElement},`);
          }
        }
      });

      if (addressString.charAt(addressString.length - 1) === ',') {
        return addressString.slice(0, -1);
      }

      return addressString;
    },

    getBuildingName(building) {
      if (building.extra_info.building_name === 'Other'
        && building.extra_info.building_other_name !== '') {
        return `<span isolate>${building.extra_info.building_name}</span><span notranslate> - ${building.extra_info.building_other_name}</span>`;
      }

      return `<span isolate>${building.extra_info.building_name}</span>`;
    },

    subBuildingAttr(currentBuilding, text) {
      /*
      Will sub the attribute in brackets with the same attribute on `currentBuilding` object

      Args:
        currentBuilding: Object of the currentBuilding
          Ex. this.$store.state.buildings.currentBuilding; (usually)
        text: The text with substitutable variables
          "The building city is: {{ city }} and building name is {{extra_info.building_name}}"
       */
      const toSub = text.match(/{{\s?([\w.]+)\s?}}/g);
      if (toSub === null) return text;

      let substituted = text;
      toSub.forEach((substring) => {
        const buildingAttrMatch = substring.match(/{{\s?([\w.]+)\s?}}/);
        const buildingAttr = buildingAttrMatch ? buildingAttrMatch[1] : null;

        let buildingVal = '';
        // Deal with special cases of buildingAttr
        switch (buildingAttr) {
          case 'extra_info.building_name':
          case 'building_name':
            // Use helper method for case of building_name 'Other'
            // Return HTML
            buildingVal = this.getBuildingName(currentBuilding);
            break;
          case 'address':
            // 'address' keyword combines address1 and address2
            buildingVal = this.getBuildingAddress(currentBuilding, ['address1', 'address2']);
            break;
          case 'zip':
            buildingVal = this.fetchFromObject(currentBuilding, buildingAttr);
            buildingVal = zipFormatter.methods.formatZip(buildingVal, currentBuilding.country);
            break;
          default:
            // Get property from building object as defined in buildingAttr
            buildingVal = this.fetchFromObject(currentBuilding, buildingAttr);
        }

        // Capitalize the new string
        if (buildingAttr.indexOf('building_name') === -1) {
          buildingVal = capitalize(buildingVal);
        }

        const translatableVariables = [
          'extra_info.community',
          'community',
          'city',
          'region',
          'country',
          'building_name',
          'extra_info.building_name',
        ];

        if (!translatableVariables.includes(buildingAttr)) {
          buildingVal = `<span notranslate>${buildingVal}</span>`;
        } else if (buildingAttr !== 'building_name' && buildingAttr !== 'extra_info.building_name') {
          buildingVal = `<span isolate>${buildingVal}</span>`;
        }

        substituted = substituted.replace(substring, buildingVal);
      });

      return substituted;
    },

    fetchFromObject(obj, prop) {
      /*
       Given an object (obj) and string property (prop), get that property from object.
       prop may contain dot notation to access sub-properties

       From: https://stackoverflow.com/questions/4255472/javascript-object-access-variable-property-by-name-as-string
       */

      if (typeof obj === 'undefined') {
        return false;
      }

      const index = prop.indexOf('.');
      if (index > -1) {
        return this.fetchFromObject(obj[prop.substring(0, index)], prop.substr(index + 1));
      }

      return obj[prop];
    },
  },
};
