import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    allBuilding: {},
    currentBuilding: {},
    paginatedBuildings: {},
  },

  mutations: {
    updateField,

    set(state, data) {
      state.currentBuilding = data;
    },

    setAll(state, data) {
      state.allBuilding = data;
    },

    setPaginated(state, data) {
      state.paginatedBuildings = data;
    },

    setExtraInfo(state, data) {
      // eslint-disable-next-line no-return-assign
      Object.keys(data).forEach((key) => state.currentBuilding.extra_info[key] = data[key]);
    },

    setNew(state, data) {
      state.currentBuilding = data;
    },

    setCurrentBuildingId(state, id) {
      state.currentBuilding.selectedId = id;
    },

    clean(state) {
      state.allBuilding = {};
      state.currentBuilding = {};
    },

    create(state, data) {
      state.currentBuilding.rebate_project.push(data);
    },

    updateEA(state, data) {
      Vue.set(state.currentBuilding.ea_assignment, data, true);
    },

    updateDocumentUploadNotify(state, id) {
      state.currentBuilding.rebate_project.find(
        (project) => project.id === id,
      ).document_upload_notify_list = [];
    },
  },

  actions: {
    update({ state, commit, dispatch }, params = state.currentBuilding) {
      const data = {
        building: params || {},
      };

      // Set building virtual_audit_mode to lite mode when creating a new building.
      if (Number.isNaN(parseInt(data.building.id, 10))) {
        const isDefaultAdvancedMode = this.state.partners.currentPartner.settings
          .enable_default_advanced_mode;
        data.building.virtual_audit_mode = isDefaultAdvancedMode ? 'advanced' : 'lite';
      }

      data.building.electric_summer_cost = 1;
      data.building.electric_summer_usage = 1;
      data.building.electric_winter_cost = 1;
      data.building.electric_winter_usage = 1;
      data.building.heating_type = 'natural_gas';
      data.building.heating_usage = 1;
      data.building.heating_cost = 1;

      return ajax.post('/api/v1/buildings/', data)
        .then((response) => {
          commit('set', response.data.data.attributes);
          dispatch('set', response.data.data.id);
          return response;
        })
        .catch((error) => Promise.reject(error));
    },

    set({ commit }, id) {
      return ajax.get(`/api/v1/buildings/current?building_id=${id}`)
        .then((response) => {
          commit('set', response.data.data.attributes);
        })
        .catch((errors) => { console.log(errors); });
    },

    get({ commit }) {
      return ajax.get('/api/v1/buildings/current')
        .then((response) => commit('set', response.data.data.attributes));
    },

    getAll({ commit }, filterParams) {
      return ajax.get('/api/v1/buildings/', { params: filterParams })
        .then((response) => {
          commit('setAll', response.data.records.data);
          commit('setPaginated', response.data);
        });
    },

    setNew({ commit }) {
      return ajax.get('/api/v1/buildings/new')
        .then((response) => commit('set', response.data.data.attributes));
    },

    setCurrentBuildingId({ commit }, id) {
      commit('setCurrentBuildingId', id);
    },

    clean({ commit }) {
      commit('clean');
    },

    setProject({ commit }, params) {
      const url = params.useOffer
        ? `/api/v1/projects?offer_id=${params.id}` : `/api/v1/projects?subsidy_id=${params.id}`;

      return ajax.post(url)
        .then((response) => commit('create', response.data.data.attributes));
    },

    updateEaAssignment({ commit }, params) {
      commit('updateEA', params);
    },

    // eslint-disable-next-line no-empty-pattern
    updateProjectRequestedEdit({ commit }, params) {
      console.log('UPDATING REQUESTED EDIT COUNTER');
      return ajax.put(`/api/v1/projects/${params.id}`, params)
        .then((response) => {
          if (params.update === 'admin_document_update_notify') {
            commit('updateDocumentUploadNotify', params.id);
            console.log(response.data.data.attributes.document_upload_notify);
          } else {
            console.log(response.data.data.attributes.requested_edit);
          }
        })
        .catch((errors) => { console.log(errors); });
    },
  },

  getters: {
    getField,

    filterOutSoftRecommendation: (state, getters, rootState) => (categoryKey) => {
      let filtered;
      const useOffer = rootState.partners.currentPartner.settings.energyxpert_admin_enabled;
      const recommendations = useOffer ? state.currentBuilding.va_recommended_offers : state
        .currentBuilding.va_recommended_subsidies;
      const otherRecommendations = useOffer ? rootState.offers.softRecommendedOffers : rootState
        .subsidies.softRecommendedSubsidies;

      if (categoryKey === '') {
        filtered = recommendations;
      } else {
        filtered = {};

        recommendations.other_recommendations = otherRecommendations;
        const recommendedCategory = rootState.partners.currentPartner.tags
          .recommended_categories.all_filters[categoryKey];
        const size = recommendedCategory.list_size;

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(recommendations)) {
          const currentObj = value
            .filter((obj) => obj.attributes.recommended_categories
              .some((item) => item === categoryKey));

          if (currentObj.length > 0) {
            filtered[key] = currentObj;
          }
        }

        filtered = getters.sortRecommendation(filtered, categoryKey);

        if (size !== undefined && size > 0) {
          filtered = Object.keys(filtered).slice(0, size).reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result[key] = filtered[key];
            return result;
          }, {});
        }
      }

      return filtered;
    },

    sortRecommendation: (state, getters) => (object, key) => {
      switch (key) {
        case 'most_energy_savings':
          return Object.keys(object).sort((a, b) => {
            if (getters.getEnergySavings(a) > getters.getEnergySavings(b)) return -1;
            if (getters.getEnergySavings(a) < getters.getEnergySavings(b)) return 1;
            return 0;
          }).reduce((result, element) => {
            // eslint-disable-next-line no-param-reassign
            result[element] = object[element];
            return result;
          }, {});
        case 'highest_rebates':
          return Object.entries(object).sort((a, b) => {
            if (a[1][0].attributes.amount > b[1][0].attributes.amount) return -1;
            if (a[1][0].attributes.amount < b[1][0].attributes.amount) return 1;
            return 0;
          }).reduce((result, element) => {
            // eslint-disable-next-line no-param-reassign
            result[`${element[0]}`] = object[`${element[0]}`];
            return result;
          }, {});

        default:
          return object;
      }
    },

    getEnergySavings: (state, getters, rootState) => (incentiveKey) => {
      let ghg = 0;

      if (rootState.virtual_audits.currentVirtualAudit.savings.ghg[incentiveKey]) {
        ghg = rootState.virtual_audits.currentVirtualAudit.savings.ghg[incentiveKey].value || 0;
      }

      return ghg;
    },

    getRequestedEditCount: (state) => (buildingId) => {
      const building = state.allBuilding.filter((obj) => obj.id === buildingId)[0];

      return building.attributes.rebate_project
        .filter((obj) => obj.requested_edit !== null)
        .map((project) => project.requested_edit)
        .reduce((a, b) => a + b, 0);
    },

    getAdminDocumentUploadCount: (state) => (buildingId) => {
      const building = state.allBuilding.filter((obj) => obj.id === buildingId)[0];

      return building.attributes.rebate_project
        .filter((obj) => obj.document_upload_notify_list !== null)
        .map((project) => project.document_upload_notify_list.length)
        .reduce((a, b) => a + b, 0);
    },
  },
};
