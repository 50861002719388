<template>
  <div class="section-template">
    <br />
    <div class="content-box p-4 mt-n4">
      <h4 class="section-title">
        {{ sectionSettings['title'] }}
      </h4>
      <span class="d-flex text-black-50" :class="filterPadding">
        {{ sectionSettings['subtitle'] }}
      </span>
      <div
        v-if="showFilter"
        class="btn btn-link pl-0 filter-link"
        @click="$bvModal.show(`${incentivesType}-incentive-tags-filter`)"
      >
        {{ sectionSettings['categories_link_text'] }}
      </div>
      <br />

      <div v-if="showFilter" class="selected-tags-list mt-1">
        <span
          class="tags-list mr-2"
          v-for="(item, key) in selectedIncentiveTags" :key="key"
        >
          <div class="btn mb-1 btn-incentive tag-btn btn-egx-list-grey">
            {{ incentiveTags[item] }}
            <span
              class="ml-2"
              role="button"
              @click="removeSelectedIncentiveTag(item)"
            >
              <font-awesome-icon :icon="['fa', 'times']" />
            </span>
          </div>
        </span>
      </div>

      <div class="incentives-list">
        <div class="text-black-50 text-center" v-if="incentives.length === 0">
          No incentives found.
        </div>
        <div
          class="mr-2"
          v-else
          v-for="(incentive, index) in incentives" :key="incentive.id"
        >
          <hr v-show="index !== 0" />
          <!-- small view -->
          <div class="row sm-view">
            <div class="col-12">
              <span class="incentive-title font-weight-bold">
                {{ incentive.attributes.recommendation_title }}
              </span>
            </div>
            <div class="col-12 pb-3">
              <div v-if="isApplied(incentive)" class="text-black-50">
                <div
                  v-for="project in currentProjects(incentive)"
                  :key="project.id"
                >
                  <span>
                    {{ projectStatusCreatedAt(project) }}
                  </span>
                </div>
              </div>
              <span v-else class="short-description">
                {{ replaceWithValues(incentive.attributes.short_description) }}
              </span>
              <div
                v-if="showIncentiveSavings
                      && sectionSettings['titleKey'] !== 'other_recommendations'"
              >
                <incentive-savings
                  :incentive-savings="virtualAuditSavings"
                  :savingsText="incentivesPageSettings.incentive_savings"
                />
              </div>
            </div>

            <div class="col-6 pr-2" v-if="!incentive.attributes.project_manager_disabled">
              <div v-if="!incentive.attributes.require_contractor">
                <div
                  class="btn mb-1 btn-egx btn-incentive"
                  @click="$bvModal.show(
                    `incentive-application-${incentivesType}-${incentive.id}`
                  )"
                  v-if="!emptyObject(incentive.attributes.project_type)"
                >
                  <span v-if="!isApplied(incentive)">
                    Apply now
                  </span>
                  <span v-else>
                    Review
                  </span>
                </div>
              </div>
              <div
                v-else-if="incentive.attributes.require_contractor && !enableSingleCta"
                class="btn mb-1 btn-egx btn-incentive"
                @click="$bvModal.show(`incentive-contact-${incentivesType}-${incentive.id}`)"
              >
                <span v-if="!isAppliedIncentives">
                  {{ toggle_contractor_cta_btn_txt(incentive) }}
                </span>
                <span v-else>
                  Review
                </span>
              </div>
            </div>
            <div class="col-6">
              <div v-if="isAppliedIncentives && !incentive.attributes.require_contractor">
                <div
                  v-if="allAlerts(incentive) > 0"
                  class="btn btn-egx-white btn-incentive"
                  @click="[
                    $bvModal.show(`incentive-application-${incentivesType}-${incentive.id}`),
                    clearAlerts(incentive)
                  ]"
                >
                  <span>
                    Messages(<var message-count>{{ allAlerts(incentive) }}</var>)
                  </span>
                </div>
                <button
                  v-else
                  class="btn btn-egx-white btn-incentive"
                  disabled
                >
                  Messages
                </button>
              </div>
              <div
                v-else
                class="btn btn-egx-white btn-incentive"
                @click="$bvModal.show(`bv-modal-${incentivesType}-${incentive.id}`)"
              >
                Learn more
              </div>
            </div>
          </div>

          <!-- md view -->
          <div class="d-flex md-view">
            <div class="d-grid pr-4 col-9">
              <span class="incentive-title font-weight-bold">
                {{ incentive.attributes.recommendation_title }}
              </span>
              <div v-if="isApplied(incentive)" class="row">
                <div
                  v-for="project in currentProjects(incentive)"
                  :key="project.id"
                  :class="currentProjects(incentive).length === 1 ? 'col-12' : 'col-6'"
                >
                  <span class="text-black-50">
                    {{ projectStatusCreatedAt(project) }}
                  </span>
                </div>
              </div>
              <span v-else class="short-description">
                {{ replaceWithValues(incentive.attributes.short_description) }}
              </span>
              <div
                v-if="showIncentiveSavings
                      && sectionSettings['titleKey'] !== 'other_recommendations'"
              >
                <incentive-savings
                  :incentiveSavings="virtualAuditSavings"
                  :savingsText="incentivesPageSettings.incentive_savings"
                  :currencyIcon="currencyIcon"
                />
              </div>
            </div>

            <div class="button d-flex flex-column justify-content-center col-3">
              <template v-if="!incentive.attributes.project_manager_disabled">
                <div v-if="!incentive.attributes.require_contractor">
                  <div
                    class="btn mb-1 btn-egx btn-incentive w-100"
                    @click="$bvModal.show(
                      `incentive-application-${incentivesType}-${incentive.id}`
                    )"
                    v-if="!emptyObject(incentive.attributes.project_type)"
                  >
                    <span v-if="!isApplied(incentive)">
                      Apply now
                    </span>
                    <span v-else>
                      Review
                    </span>
                  </div>
                </div>

                <div
                  v-else-if="incentive.attributes.require_contractor && !enableSingleCta"
                  class="btn mb-1 btn-egx btn-incentive w-100"
                  @click="$bvModal.show(`incentive-contact-${incentivesType}-${incentive.id}`)"
                >
                  <span v-if="!isAppliedIncentives">
                    {{ toggle_contractor_cta_btn_txt(incentive) }}
                  </span>
                  <span v-else>
                    Review
                  </span>
                </div>
              </template>

              <div
                v-if="isAppliedIncentives &&
                (!incentive.attributes.require_contractor || allAlerts(incentive))"
              >
                <div
                  v-if="allAlerts(incentive) > 0"
                  class="btn btn-egx-white btn-incentive w-100"
                  @click="[
                     $bvModal.show(selectedModal(incentive)),
                     clearAlerts(incentive)
                  ]"
                >
                  <span>
                    Messages(<var message-count>{{ allAlerts(incentive) }}</var>)
                  </span>
                </div>
                <button
                  v-else
                  class="btn btn-egx-white btn-incentive w-100"
                  disabled
                >
                  Messages
                </button>
              </div>
              <a
                v-else-if="incentive.attributes.learn_more_url"
                class="btn btn-egx-white btn-incentive"
                :href="incentive.attributes.learn_more_url"
                target="_blank"
              >
                Learn more
              </a>
              <div
                v-else
                class="btn btn-egx-white btn-incentive"
                @click="$bvModal.show(`bv-modal-${incentivesType}-${incentive.id}`)"
              >
                Learn more
              </div>
            </div>
          </div>

          <b-modal
            :id="`bv-modal-${incentivesType}-${incentive.id}`"
            :size="'xl'"
            centered
            class="egx-partner-color custom-modal app"
            :title="incentive.attributes.recommendation_title"
            hide-footer
            @show="fetchDescription(incentive.id)"
            @hidden="resetDescription"
          >
            <div class="text-center" v-if="description.isFetching" :style="customCss">
              <font-awesome-icon
                :icon="['fa', 'circle-notch']"
                class="fa-spin m-auto spinner fa-3x egx-partner-color"
              />
            </div>
            <div v-else>
              <span v-html="description.value" />
            </div>
          </b-modal>

          <incentive-application
            :currentIncentive="incentive"
            :hasDocumentUploadNotify="hasDocumentUploadNotify(incentive)"
            :currentProjects="currentProjects(incentive)"
            :applied="projectApplied(incentive)"
            :incentiveType="incentivesType"
          />

          <incentive-contact
            :currentIncentive="incentive"
            :shortDescription="replaceWithValues(incentive.attributes.short_description)"
            :hasDocumentUploadNotify="hasDocumentUploadNotify(incentive)"
            :currentProjects="currentProjects(incentive)"
            :applied="projectApplied(incentive)"
            :incentiveType="incentivesType"
          />
        </div>
      </div>
      <incentive-tags-filter
        :idPrefix="this.incentivesType"
        :tag-store-name="this.tagStoreName"
      />
    </div>
  </div>
</template>

<script>
import IncentiveApplication from './IncentiveApplication.vue';
import IncentiveTagsFilter from './IncentiveTagsFilter.vue';
import emptyObjectMixin from '../shared/mixins/empty-object';
import IncentiveContact from './IncentiveContact.vue';
import IncentiveSavings from './IncentiveSavings.vue';

export default {
  name: 'Incentives',

  mixins: [emptyObjectMixin],

  props: {
    incentivesArray: {
      Array,
      required: true,
    },
    incentivesType: {
      Array,
      required: true,
    },
    tagStoreName: {
      Array,
      required: true,
    },
    sectionSettings: {
      Object,
      required: true,
    },
    virtualAuditSavings: {
      Object,
      required: false,
    },
    showFilter: {
      Boolean,
      required: true,
    },
    enableSingleCta: {
      Boolean,
      required: false,
    },
    isOffer: {
      Boolean,
      required: false,
    },
    showIncentiveSavings: Boolean,
    isOverview: Boolean,
    currencyIcon: String,
  },

  components: {
    IncentiveApplication,
    IncentiveTagsFilter,
    IncentiveContact,
    IncentiveSavings,
  },

  created() {
    if (this.incentivesType === 'applied') {
      if (this.isOffer) {
        this.getOfferUserAlerts();
      } else {
        this.getUserAlerts();
      }
    }
  },

  data() {
    return {
      type: 'Incentives',
      showModal: false,
      userAlerts: {},
      description: {
        value: '',
        isFetching: false,
      },
    };
  },

  mounted() {
    this.$store.dispatch(`subsidies/set${this.incentiveTagDispatchName}`, []);
  },

  computed: {
    isAppliedIncentives() {
      return this.incentivesType === 'applied';
    },

    allIncentives() {
      // This incentivesArray could also be offersArray
      return this.incentivesArray;
    },

    selectedIncentiveTags() {
      return this.$store.state.subsidies[this.tagStoreName];
    },

    incentiveTagDispatchName() {
      return this.tagStoreName.charAt(0).toUpperCase() + this.tagStoreName.slice(1);
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    incentiveTags() {
      return this.partner.tags.incentive_categories;
    },

    incentives() {
      const filtered = this.allIncentives.filter(
        (obj) => obj.attributes.incentive_tags.some(
          (item) => this.selectedIncentiveTags.includes(item),
        ),
      );

      return this.selectedIncentiveTags.length === 0 ? this.allIncentives : filtered;
    },

    incentivesPageSettings() {
      return this.$store.state.partners.currentPartner.settings.sections.incentives.attributes;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    eaAssignment() {
      return this.currentBuilding.ea_assignment;
    },

    filterPadding() {
      return this.showFilter ? 'pb-3' : '';
    },

    documents() {
      return this.$store.state.documents.documents;
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },

    virtualAudit() {
      return this.$store.state.virtual_audits.currentVirtualAudit;
    },

    virtualAuditPlaceholders() {
      return this.virtualAudit.placeholder_mapping;
    },
  },

  methods: {
    currentProjects(incentive) {
      // For the incentive page, if incentivesType is 'available' (in available section)
      // and it's not maxed out. show all title and popup like it's a new offer
      if (incentive.attributes.quantity_limitation_active) {
        if (this.incentivesType === 'available' && this.availableToApply(incentive)) return [];
      }
      const projectType = incentive.attributes.project_type;
      // TODO: this rebate project attribute should be plural
      const rebateProjects = this.currentBuilding.rebate_project;

      return rebateProjects.filter((project) => project.project_type.id === projectType.id);
    },

    availableToApply(incentive) {
      return this.$store.getters['offers/checkAvailableQuantity'](incentive);
    },

    projectApplied(incentive) {
      if (this.currentProjects(incentive).length === 0
        && this.availableToApply(incentive)) return false;
      return !!this.currentProjects(incentive);
    },

    hasDocumentUploadNotify(incentive) {
      return this.isApplied(incentive)
        && this.currentProjects(incentive)
          .filter((project) => project.document_upload_notify_list !== null).length > 0
        && this.currentProjects(incentive)
          .filter((project) => project.document_upload_notify_list !== []).length > 0;
    },

    clearAlerts(incentive) {
      this.$delete(this.userAlerts, incentive.id);
    },

    getOfferUserAlerts() {
      this.currentBuilding.rebate_project.forEach((project) => {
        this.userAlerts[project.programmable_id] = this.userAlerts[project.programmable_id] || 0;
        this.userAlerts[project.programmable_id] += project.requested_edit || 0;
      });
    },

    getUserAlerts() {
      this.incentives.forEach((incentive) => {
        if (incentive.attributes.question_list
          && incentive.attributes.question_list[0]
          && incentive.attributes.question_list[0].editable
        ) {
          incentive.attributes.question_list[0].question_list_item.forEach((item) => {
            this.userAlertCheck(incentive, item.id, 'ListItem');
          });
        }

        if (incentive.attributes.document_list
          && incentive.attributes.document_list[0]
          && incentive.attributes.document_list[0].editable
        ) {
          incentive.attributes.document_list[0].document_list_item.forEach((item) => {
            this.userAlertCheck(incentive, item.id, 'ListItem');

            if (this.documents[`ListItem${item.id}`]) {
              this.documents[`ListItem${item.id}`].forEach((document) => {
                this.userAlertCheck(incentive, document.id, 'Document');
              });
            }
          });
        }

        return [];
      });
    },

    userAlertCheck(incentive, itemId, itemName) {
      const params = {
        commentable_type: itemName,
        commentable_id: itemId,
        building_id: this.currentBuilding.id,
        all_owners: true,
        project_id: 'all',
      };

      const self = this;

      this.$store.dispatch('comments/get', params).then((response) => {
        if (response.data.data[0] !== undefined) {
          response.data.data.forEach((data) => {
            if (data.attributes.requested_comments.length > 0) {
              const incentiveArray = { ...self.userAlerts };

              if (incentiveArray[incentive.id] === undefined) {
                incentiveArray[incentive.id] = [];
              }

              incentiveArray[incentive.id].push(data.attributes);
              self.userAlerts = incentiveArray;
            }
          });
        }
      });
    },

    removeSelectedIncentiveTag(tag) {
      const currentTags = [...this.selectedIncentiveTags];
      currentTags.splice(currentTags.indexOf(tag), 1);
      this.$store.dispatch(`subsidies/set${this.incentiveTagDispatchName}`, currentTags);
    },

    isApplied(incentive) {
      // Check if offer maxed out
      // the getter return true when it's still available for apply
      // therefore, for this method it needs to return false to indicate it's still available
      // Also, if it's for incentive pag4e applied section, should return true

      if (this.isAppliedIncentives) return true;

      if (incentive.attributes.quantity_limitation_active) {
        return !this.availableToApply(incentive);
      }

      const projectType = incentive.attributes.project_type;
      const rebateProjects = this.currentBuilding.rebate_project;
      const project = rebateProjects.find((p) => p.project_type.id === projectType.id);

      return !!project;
    },

    allAlerts(incentive) {
      let count = 0;

      if (this.userAlerts[incentive.id] !== undefined) {
        if (this.isOffer) {
          count += this.userAlerts[incentive.id];
        } else {
          count += this.userAlerts[incentive.id].length;
        }
      }

      if (this.hasDocumentUploadNotify(incentive)) {
        count = this.currentProjects(incentive)
          .filter((project) => project.document_upload_notify_list !== null
            && project.document_upload_notify_list !== [])
          .reduce((accumulator, object) => accumulator + object
            .document_upload_notify_list.length, 0);
      }

      return count;
    },

    selectedModal(incentive) {
      return incentive.attributes.require_contractor
        ? `incentive-contact-${this.incentivesType}-${incentive.id}`
        : `incentive-application-${this.incentivesType}-${incentive.id}`;
    },

    toggle_contractor_cta_btn_txt(incentive) {
      return this.eaAssignmentNotAvailable(incentive)
        ? this.incentivesPageSettings.contractor_cta_btn.contacted_text
        : this.incentivesPageSettings.contractor_cta_btn.contact_text;
    },

    eaAssignmentNotAvailable(incentive) {
      if (incentive.attributes.quantity_limitation_active) {
        return !this.$store.getters['offers/checkAvailableQuantity'](incentive);
      }

      return this.eaAssignment[incentive.attributes.project_type.id];
    },

    fetchDescription(incentiveId) {
      this.description.isFetching = true;
      this.$store.dispatch('subsidies/getDescription', incentiveId)
        .then((response) => {
          this.description.isFetching = false;
          this.description.value = response.data.description;
        });
    },

    resetDescription() {
      this.description.value = '';
      this.description.isFetching = false;
    },

    replaceWithValues(description) {
      if (!this.virtualAuditPlaceholders) {
        return description;
      }

      let newDescription = description;
      const rxp = /{{([^}]+)}}/g;
      let curMatch = true;
      let mappedValue = null;

      while (curMatch) {
        curMatch = rxp.exec(description);

        if (curMatch) {
          mappedValue = this.virtualAuditPlaceholders[curMatch[1]];

          if (mappedValue !== null && mappedValue !== undefined) {
            newDescription = newDescription.replace(
              curMatch[0], mappedValue,
            );
          }
        }
      }

      return newDescription;
    },

    projectStatusCreatedAt(project) {
      return `${project.id} - ${project.project_status.status} (${this.formatDate(project.created_at)})`;
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US').split('/').join('-');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/pages/incentives';
</style>
