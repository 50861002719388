<template>
  <div class="answer-form">
    <div class="row pb-2">
      <div class="col-12 col-lg-4">
        <label :for="listItem.name" class="sub-section-title">
          {{ listItem.name }}
          <span class="text-danger" notranslate v-if="isListItemRequired(listItem)">*</span>
        </label>
        <p class="mb-0 description-small">{{ listItem.description }}</p>
      </div>
      <div class="m-2 pl-3 text-center" v-if="isLoading">
        <font-awesome-icon
          :icon="['fa', 'circle-notch']"
          class="fa-spin m-auto spinner fa-2x"
        />
      </div>
      <template v-else>
        <div
          class="col-8 col-lg-6 pt-2 pt-lg-0"
          v-show="showInputFields"
        >
          <validation-provider
            mode="lazy"
            :rules="addValidation(listItem)"
            v-slot="{ errors }"
            :ref="'ql-item-' + listItem.id"
          >

            <toggle-button
              v-model="comment.content"
              class="question-list-item"
              :name="listItem.name"
              :id="listItem.id"
              :width="62"
              :height="25"
              :font-size="15"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              v-if="isBooleanAnswerType"
            />

            <b-input-group class="mb-3" v-else-if="isDateAnswerType">
              <b-form-input
                :id="`${listItem.id}`"
                v-model="comment.content"
                :name="listItem.name"
                type="text"
                class="question-list-item d-none"
              />
              <b-input-group-append class="w-100">
                <b-form-datepicker v-model="comment.content" :locale="currentLocale" />
              </b-input-group-append>
            </b-input-group>

            <div v-else-if="isMultiSelectAnswerType">
              <multiselect
                v-model="comment.content"
                tag-placeholder="Add this as new option"
                placeholder="Select all that apply."
                :options="listItem.select_options"
                :multiple="true"
                :taggable="true"
              />
              <input
                type="text"
                :name="listItem.name"
                v-model="comment.content"
                :id="listItem.id"
                class="question-list-item form-control multi-select d-none"
              />
            </div>
            <div v-else-if="isSelectAnswerType">
              <multiselect
                v-model="comment.content"
                :options="listItem.select_options"
                :multiple="false"
                :taggable="false"
              />
              <input
                type="text"
                :name="listItem.name"
                v-model="comment.content"
                :id="listItem.id"
                class="question-list-item form-control single-select d-none"
              />
            </div>

            <input
              type="text"
              :name="listItem.name"
              v-model="comment.content"
              :id="listItem.id"
              class="question-list-item form-control"
              v-else
            />

            <span class="msg-error text-danger">{{ errMsg = errors[0] }}</span>
          </validation-provider>
        </div>
        <div v-show="!showInputFields" class="col-8 col-lg-6 pt-2 pt-lg-0">
          <p class="mb-0 col-lg-8 col-sm-12 my-auto text-secondary">
            <span notranslate="disableTranslation">
              {{ currentCommentContent() }}
            </span>
          </p>
        </div>
        <div
          class="col-4 col-lg-2 pt-2 pt-lg-0 update-edit-comment mt-1"
          v-if="(!applied && displayBtn() && editable) ||
            !editedRequestedComment && !emptyObject(comment.requested_comments)
            && !hideRequestedUpdates
          "
        >
          <button
            v-if="showInput"
            class="btn btn-outline-primary btn-sm edit-btn"
            @click="updateAnswer()"
            :class="currentSubSection.btns.class"
            :style="currentSubSection.btns.style"
          >
            Update
          </button>
          <button
            v-else
            class="btn btn-outline-primary btn-sm edit-btn"
            @click="editAnswer()"
            :class="currentSubSection.btns.class"
            :style="currentSubSection.btns.style"
          >
            Edit
          </button>
        </div>
        <div
          v-if="
            !editedRequestedComment && !emptyObject(comment.requested_comments)
            && !hideRequestedUpdates
          "
          class="pl-3"
        >
          <p class="requested-comment-content text-danger">
            <small>We need you to update your answer. Reason:
              <var admin-comment>{{ comment.requested_comments[0].content }}</var>
            </small>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import emptyObjectMixin from '../shared/mixins/empty-object';

export default {
  name: 'Answer',

  components: {
    Multiselect,
  },

  props: {
    listItem: Object,
    commentableType: String,
    commentableId: [String, Number],
    displayOnly: { type: Boolean, default: false },
    buildingId: [String, Number],
    editable: { type: Boolean, default: true },
    applied: { Boolean, Object },
    currentSubSection: Object,
    hideRequestedUpdates: { Boolean, default: false },
    currentProject: { type: Object, default: () => ({}) },
  },

  mixins: [emptyObjectMixin],

  created() {
    if (this.isBooleanAnswerType) {
      this.comment.content = false;
    }
    this.getComment();
  },

  data() {
    return {
      comment: {
        content: '',
        commentable_id: this.commentableId,
        commentable_type: this.commentableType,
        requested_comments: {},
      },
      showTopAlert: false,
      showInput: false,
      editedRequestedComment: false,
      errMsg: '',
      editCounter: 0,
      isLoading: true,
    };
  },

  watch: {
    errMsg(newValue) {
      if (newValue) {
        this.editAnswer();
      }
    },

    showInput(newValue) {
      if (newValue) {
        this.editAnswer();
      }
    },

    hideRequestedUpdates(newValue) {
      if (newValue) {
        this.hideEditAnswer();
      }
    },

    deep: true,
  },

  computed: {
    isBooleanAnswerType() {
      return this.listItem.answer_type === 'boolean';
    },

    isDateAnswerType() {
      return this.listItem.answer_type === 'date';
    },

    isMultiSelectAnswerType() {
      return this.listItem.answer_type === 'multi_select';
    },

    isSelectAnswerType() {
      return this.listItem.answer_type === 'select';
    },

    showInputFields() {
      return (
        (!this.applied && this.showInputField() && this.editable)
        || (!this.editedRequestedComment && this.showInputField() && this.editable)
      );
    },

    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },

  methods: {
    getComment() {
      const self = this;

      const params = {
        commentable_type: this.commentableType,
        commentable_id: this.commentableId,
        building_id: this.buildingId,
        projectId: this.currentProject?.id,
        all_owners: true,
      };

      this.$store.dispatch('comments/get', params).then((response) => {
        if (response.data.data[0] !== undefined) {
          const { attributes } = response.data.data[0];

          if (this.isBooleanAnswerType) {
            attributes.content = attributes.content === 'true';
          } else if (this.isMultiSelectAnswerType && attributes.content) {
            attributes.content = JSON.parse(attributes.content).filter((item) => item);
          }

          self.comment = attributes;
          self.hideEditAnswer();
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    showInputField() {
      return !this.comment.id || this.showInput;
    },

    displayBtn() {
      return !!this.comment.id;
    },

    editAnswer() {
      if (this.editCounter === 0 && this.comment.requested_comments.length > 0) this.comment.content = '';

      this.editCounter += 1;
      this.showInput = true;
    },

    hideEditAnswer() {
      this.$nextTick(() => {
        this.showInput = false;
        this.errMsg = '';
      });
    },

    async updateAnswer() {
      const self = this;
      const currentComment = { ...self.comment };
      currentComment.content_not_required = true;

      if (this.isBooleanAnswerType) {
        currentComment.content = currentComment.content.toString();
      }

      const data = {
        comment: currentComment,
      };

      if (this.applied) {
        data.requested_comment_id = self.comment.requested_comments[0].id;
      } else {
        this.$store.dispatch('comments/put', data).then(() => {
          console.log('Answer Updated!');
        }).catch((error) => {
          console.log(error);
        });
      }

      this.$store.dispatch('comments/localUpdate', data).then(() => {
        self.hideEditAnswer();
      }).catch((error) => {
        console.log(error);
      });
    },

    addValidation(listItem) {
      const validationList = [];

      if (listItem.is_required === 'true'
        || Object.keys(this.comment.requested_comments).length > 0
      ) {
        validationList.push('required');
      }

      if (listItem.answer_type === 'integer') {
        validationList.push('numeric');
      } else if (listItem.answer_type === 'float') {
        validationList.push('decimal');
      }

      return validationList.join('|');
    },

    isListItemRequired(listItem) {
      return listItem.is_required === 'true';
    },

    currentCommentContent() {
      let currentContent = this.comment.content;

      if (this.isBooleanAnswerType) {
        if (this.comment.content) {
          currentContent = 'Yes';
        } else {
          currentContent = 'No';
        }
      } else if (this.isMultiSelectAnswerType && currentContent) {
        currentContent = currentContent.join(', ');
      }

      return currentContent;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
@import '../assets/stylesheets/components/incentive_application';
</style>
