<template>
  <div class="site-header">
    <b-navbar toggleable="lg" type="light" variant="light">

      <a class="navbar-brand router-link-active" :href="partnerLogoLink" target="_blank">
        <img
          :src="partnerLogo"
          class="partner-logo-header"
          :class="partnerLogoAttributes.class"
          :style="partnerLogoAttributes.style"
          alt=""
        />
      </a>

      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

      <b-collapse
        id="nav-text-collapse"
        class="font-weight-normal pt-2"
        is-nav
        v-if="!maintenanceMode"
      >
        <b-navbar-nav
          v-show="!emptyObject(this.currentUser) && currentRouteName !== 'sign-up'"
          class="pl-1 pr-2"
        >
          <b-nav-text>
            <router-link
              :to="{ name: 'buildings-list' }"
              active-class="active"
              class="nav-link p-1 site-header__text"
            >
              {{ header.building_list ? header.building_list.text : 'Your buildings' }}
            </router-link>
          </b-nav-text>
        </b-navbar-nav>
        <b-navbar-nav
          v-if="!emptyObject(this.currentUser) && !objectIdNull(this.currentBuilding) &&
                !emptyObject(this.currentBuilding) && !hideNavOptions && is_incentive_enabled"
          class="pl-1 pr-2"
        >
          <b-nav-text>
            <router-link
              :to="{ name: 'incentives' }"
              active-class="active"
              class="nav-link p-1 site-header__text"
            >
              Find rebates
            </router-link>
          </b-nav-text>
        </b-navbar-nav>
        <b-nav-item-dropdown
          :text="header.home_report ? header.home_report.text : 'Home Report'"
          v-show="!emptyObject(this.currentUser) &&
                  !objectIdNull(this.currentBuilding) && !emptyObject(this.currentBuilding) &&
                   liteAuditComplete && !hideNavOptions && auditComplete && is_assessment_enabled"
        >
          <router-link tag="b-dropdown-item" :to="{ name: 'home-overview' }">
            {{ header.home_report ? header.home_report.dropdowns.overview.text : 'Overview' }}
          </router-link>
          <router-link tag="b-dropdown-item" :to="{ name: 'home-analysis' }">
            {{ header.home_report ? header.home_report.dropdowns.analysis.text : 'Analysis' }}
          </router-link>
          <router-link tag="b-dropdown-item" @click.native="updateToAssessment()" to="">
            {{
              header.home_report
                ? header.home_report.dropdowns.update_virtual_assessment.text
                : 'Update Virtual Assessment'
            }}
          </router-link>
        </b-nav-item-dropdown>

        <hr v-if="userName" class="divider" />

        <b-navbar-nav v-if="userName" class="d-block ml-auto">
          <b-nav-item-dropdown no-caret right>
            <template v-slot:button-content>
              <span class="btn btn-egx dropdown-toggle" :notranslate="userName !== 'New user'">
                {{ userName }}
              </span>
            </template>
            <router-link :tag="'b-dropdown-item'" :to="{ name: 'UserEdit' }" class="nav-item">
              Profile
            </router-link>
            <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav v-else class="d-block ml-auto">
          <template v-if="!userName && !ssoIssuerLoginOnly && showSignUpButton">
            <router-link
              :to="{ name: 'sign-in' }"
              class="ml-2 nav-link"
              v-if="hideUserPassLogin"
            >
              <div class="btn btn-egx">Sign in</div>
            </router-link>
            <router-link
              :to="{ name: toggleSignInAndUpRoutes }"
              class="ml-2 nav-link"
              v-else
            >
              <div class="btn btn-egx">{{ toggleSignInAndUp }}</div>
            </router-link>
          </template>
        </b-navbar-nav>

        <b-navbar-nav class="d-block company-contact" v-if="showContactButton">
          <b-nav-item-dropdown no-caret right>
            <template v-slot:button-content>
              <span class="btn btn-egx-white" :class="header.contact.class">
                {{ header.contact.text }}
              </span>
            </template>
            <b-nav-text>
              <div v-html="header.contact.html" isolate class="p-2 text-black" />
            </b-nav-text>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <div class="mt-1" />
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

import router from '../../router/index';
import emptyObjectMixin from '../../shared/mixins/empty-object';

export default {
  name: 'SiteHeader',

  mixins: [emptyObjectMixin],

  data() {
    return {
      advancedAudit: false,
    };
  },

  methods: {
    async signOut() {
      const routerName = this.isSLOEnabled ? 'saml-logout' : 'home';

      await this.$store.dispatch('auth/signOut')
        .then(() => router.push({ name: routerName })
          .catch(() => {
          }));
    },

    updateToAssessment() {
      this.$store.dispatch('generic_items/setAllCurrentGenericItems')
        .then(() => {
          router.push({ name: 'fuel-type' });
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('buildings/setNew');
            router.push({ name: 'building-details' });
          }
        });
    },
  },

  computed: {
    liteAuditComplete() {
      return true;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    partnerLogo() {
      return this.partner.settings.partner_logo_url;
    },

    partnerLogoLink() {
      return this.partner.settings.partner_logo_link_url;
    },

    header() {
      return this.partner.settings.sections.homepage.header;
    },

    userName() {
      return this.$store.state.users.currentUser.name;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    hideNavOptions() {
      const pageNames = ['buildings-list', 'building-details'];

      return pageNames.includes(this.$route.name);
    },

    isCurrentPageSignIn() {
      return this.$route.name === 'sign-in';
    },

    toggleSignInAndUp() {
      return this.isCurrentPageSignIn ? 'Sign up' : 'Sign in';
    },

    toggleSignInAndUpRoutes() {
      return this.isCurrentPageSignIn ? 'sign-up' : 'sign-in';
    },

    auditComplete() {
      return this.currentBuilding.extra_info.virtual_audit;
    },

    partnerLogoAttributes() {
      return this.header.logo || {};
    },

    is_incentive_enabled() {
      return this.partner.settings.enable_incentives;
    },

    is_assessment_enabled() {
      return this.partner.settings.enable_assessment;
    },

    isSLOEnabled() {
      return this.currentUser.third_party_signup;
    },

    maintenanceMode() {
      return this.partner.maintenance_mode && !this.bypassMaintenanceMode;
    },

    bypassMaintenanceMode() {
      if (!this.currentUser) return false;

      const testerEmails = this.partner.settings.tester_emails
        .split(',')
        .filter((email) => email !== '')
        .map((email) => email.trim());

      return testerEmails.includes(this.currentUser.email);
    },

    showContactButton() {
      // Defaults to true
      return this.header.contact.enabled !== false;
    },

    currentRouteName() {
      return this.$route.name;
    },

    hideUserPassLogin() {
      return this.partner.sso.hide_user_pass_login;
    },

    ssoIssuerLoginOnly() {
      return this.partner.sso.allowed && this.partner.sso.issuer_login_only;
    },
    soapUserAuthentication() {
      return this.partner.settings.soap_user_authentication;
    },
    showSignUpButton() {
      let display = true;
      if (this.soapUserAuthentication && this.soapUserAuthentication.allowed) {
        display = !this.isCurrentPageSignIn;
      }
      return display;
    },
  },
};

</script>

<style lang="scss">
@import '../../assets/stylesheets/components/headers/site_header.scss';
</style>
