<template>
  <div v-if="!emptyObject(currentIncentive.attributes.project_type)">
    <b-modal
      :id="`incentive-application-${incentiveType}-${currentIncentive.id}`"
      :size="'lg'"
      centered
      hide-footer
      scrollable
      @show="resetModal"
      @hide="onModalClose"
      :title="currentIncentive.attributes.recommendation_title || currentIncentive.attributes.title"
      :ref="`incentive-application-${incentiveType}-${currentIncentive.id}`"
    >
      <div class="m-4 text-center" v-if="isLoading">
        <font-awesome-icon
          :icon="['fa', 'circle-notch']"
          class="fa-spin m-auto spinner fa-4x"
        />
      </div>
      <div :style="customCss" v-else>
        <!-- User building details -->
        <div class="row d-flex justify-content-between m-2">
          <obj-detail-display-edit
            class="col-12 col-md-6 mb-2"
            :editBtnPath="'/user/edit'"
            :applied="applied"
            :editBtn="currentSection.info_section.user_info.edit_btn"
            :editSection="'user'"
            @section-edit="displayEditField"
            :class="checkIfNotSelected('user')"
          >
            <div slot="title" class="sub-section-title">
              <p
                :style="currentSection.info_section.building_info.title.style"
                :class="currentSection.info_section.building_info.title.class"
                class="mb-0"
              >
                {{ currentSection.info_section.user_info.title.text }}
              </p>
            </div>
            <div slot="sub-title" class="sub-section-title" notranslate>
              {{ currentUser.name }}
            </div>
            <div slot="content" class="detail-section">
              <p class="mb-0">
                Email: <var user-email>{{ currentUser.email }}</var>
              </p>
              <p class="mb-0">
                Mailing Address: <var user-address>{{ currentUser.mailing_address }}</var>
              </p>
            </div>
          </obj-detail-display-edit>
          <obj-detail-display-edit
            class="col-12 col-md-6 mb-2"
            :editBtnPath="`/buildings/${currentBuilding.id}`"
            :applied="applied"
            :editBtn="currentSection.info_section.building_info.edit_btn"
            :editSection="'building'"
            @section-edit="displayEditField"
            :class="checkIfNotSelected('building')"
          >
            <div slot="title" class="sub-section-title">
              <p
                :class="currentSection.info_section.building_info.title.class"
                :style="currentSection.info_section.building_info.title.style"
                class="mb-0"
              >
                {{ currentSection.info_section.building_info.title.text }}
              </p>
            </div>
            <div slot="sub-title" class="sub-section-title" notranslate>
              {{ currentBuilding.address1 }}
            </div>
            <div slot="content" class="detail-section">
              <p class="mb-0" notranslate>
                {{ currentBuilding.address1 }} {{ currentBuilding.address2 }}
              </p>
              <p class="mb-0">
              <span isolate>
                {{ currentBuilding.extra_info.community }}
              </span>
                <span isolate>
                {{ currentBuilding.region }}
              </span>
                <span notranslate>
                {{ formatZip(currentBuilding.zip, currentBuilding.country) }}
              </span>
              </p>
            </div>
          </obj-detail-display-edit>
        </div>

        <div class="container">
          <building-form-fields
            v-if="currentIncentive.id && editFields === 'building'"
            @update-success="hideEditFields()"
          />
          <user-form-fields
            v-if="currentIncentive.id && editFields === 'user'"
            @update-success="hideEditFields()"
          />
        </div>

        <!-- Not Applied -->
        <div v-if="!applied">
          <div class="row m-1">
            <div class="col-10">
              <div class="popup-title">
                <h3
                  :class="currentSection.title.apply_now.class"
                  :style="currentSection.title.apply_now.style"
                >
                  {{ currentSection.title.apply_now.text }}
                </h3>
              </div>
              <div class="popup-sub-title">
                <p>{{ currentSection.sub_title.apply_now.text }}</p>
              </div>
            </div>
          </div>
          <div class="required-section m-2 pb-1" v-if="allDocumentList().length > 0">
            <p
              class="m-3 sub-section-title pt-2"
              :class="currentSection.document_section.title.class"
              :style="currentSection.document_section.title.style"
            >
              {{ currentSection.document_section.title.text }}
            </p>
            <div v-for="list in allDocumentList()" :key="list.id">
              <div v-for="(listItem, index) in list.document_list_item" :key="listItem.id">
                <div class="document-section m-3">
                  <div class="row p-2">
                    <p class="col-2 col-md-1 emoji-icon mb-0">&#x1F4C4;</p>
                    <div class="col-6 col-md-8 col-lg-9">
                      <p class="mb-0 sub-section-title">
                        {{ listItem.name }}
                        <span
                          class="text-danger"
                          v-if="isDocumentRequired(listItem)"
                          notranslate
                        >
                          *
                        </span>
                      </p>
                      <p class="mb-0 description-small">{{ listItem.description }}</p>
                    </div>
                    <label
                      class="btn btn-link col-4 col-md-1"
                      v-if="!applied || requestUpload.includes(listItem.id)"
                      @change="createDocument(
                        $event.target.files,`document-listItem-${listItem.id}`
                      )"
                      :class="currentSection.document_section.upload_btn.class"
                      :style="currentSection.document_section.upload_btn.style"
                    >
                      {{ currentSection.document_section.upload_btn.text }}
                      <input class="d-none" type="file" />
                    </label>
                  </div>
                  <document
                    :class="`listItem-${index}`"
                    :documentableType="'ListItem'"
                    :documentableId="listItem.id"
                    :ref="`document-listItem-${listItem.id}`"
                    :applied="applied"
                    :documentSection="currentSection.document_section"
                    :is_requested=requestUpload.includes(listItem.id)
                    @request-upload="updateUploadObj"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="required-section m-2 pb-1" v-if="questionList.length > 0">
            <p
              class="m-3 sub-section-title pt-2"
              :class="currentSection.question_section.title.class"
              :style="currentSection.question_section.title.style"
            >
              {{ currentSection.question_section.title.text }}
            </p>
            <div v-for="list in questionList" :key="list.id" class="m-3">
              <validation-observer
                @submit.prevent=""
                :ref="`q-list-${list.id}`"
              >
                <div
                  v-for="(listItem, index) in list.question_list_item"
                  :key="listItem.id"
                  :class="['pb-2', `question-list-${list.id}`]"
                >
                  <answer
                    :class="`listItem-${index}`"
                    :commentableType="'ListItem'"
                    :commentableId="listItem.id"
                    :buildingId="currentBuilding.id"
                    :listItem="listItem"
                    :ref="`answerComponent-${listItem.id}`"
                    :editable="list.editable"
                    :applied="applied"
                    :currentSubSection="currentSection.question_section"
                    :hideRequestedUpdates="hideRequestedUpdates"
                  />
                </div>
                <div
                  class="text-center"
                  v-if="!applied && !answerExist(list.question_list_item)
                    && list.editable && isAnswerSaveAllowed"
                >
                  <button
                    class="btn btn-outline-primary btn-sm
                        edit-btn text-center save-itemList-answer"
                    @click.once="saveAnswer(list.id)"
                    :class="currentSection.question_section.btns.class"
                    :style="currentSection.question_section.btns.style"
                  >
                    Save
                  </button>
                </div>
              </validation-observer>
            </div>

            <div v-if="getCommentHistory().length > 0">
              <hr>
              <div class="m-2 sub-section-title pt-2 d-flex justify-content-between mr-3 ml-3">
                <span>Action Log</span>
                <span
                  v-b-toggle="`toggle-action-log-${currentIncentive.id}`"
                  class="font-weight-light egx-partner-color"
                >
                <u class="mr-2">View All</u>
              </span>
              </div>
              <b-collapse
                class="m-auto p-1"
                :id="`toggle-action-log-${currentIncentive.id}`"
                tag="div"
                v-for="comment in getCommentHistory()"
                :key="`comment-history-${comment.id}`"
              >
                <hr class="m-3">
                <div class="d-md-flex d-block mr-3 ml-3">
                <span class="align-content-start" notranslate>
                  {{ formatDate(comment.created_at) }}
                </span>
                  <br class="d-md-none">
                  <span class="ml-auto" v-if="comment.object_version.content !== ''">
                  <span class="text-black-50"> Updated </span>
                  <span>{{ getCommentListItem(comment).name }}</span>
                  <span class="text-black-50"> from </span>
                  <span><var item-prev-version>{{ comment.object_version.content }}</var></span>
                  <span v-if="comment.next_version && comment.next_version.content !== ''">
                    <span class="text-black-50"> to </span>
                    <span><var item-next-version>{{ comment.next_version.content }}</var></span>
                  </span>
                </span>
                  <div v-else class="ml-auto">
                    <span class="text-black-50"> Removed </span>
                    <span>{{ getCommentListItem(comment).name }}</span>
                  </div>
                </div>
              </b-collapse>
            </div>

          </div>
        </div>

        <!-- Applied -->
        <div v-else>
          <div v-if="applied && currentProjects.length === 0">
            <p :style="currentSection.not_appliable_message_to_user.style">
              {{ currentSection.not_appliable_message_to_user.text }}
            </p>
          </div>
          <div v-for="(project, projectIndex) in currentProjects" :key="project.id">
            <hr v-show="projectIndex !== 0" class="my-4" />

            <div class="row m-1">
              <div class="col-10">
                <div class="popup-title">
                  <h3
                    :class="currentSection.title.applied.class"
                    :style="currentSection.title.applied.style"
                  >
                    {{ currentSection.title.applied.text }}
                  </h3>
                </div>
                <div class="popup-sub-title">
                  <p class="mb-0">Application ID: {{ project.id }}</p>
                  <p class="mb-0">
                    Application date:
                    <var application-date>{{ formatDate(project.created_at) }}</var>
                  </p>
                  <p>Status: {{ project.project_status.status }}</p>
                </div>
              </div>
            </div>
            <div class="required-section m-2 pb-1" v-if="allDocumentList(project).length > 0">
              <p
                class="m-3 sub-section-title pt-2"
                :class="currentSection.document_section.title.class"
                :style="currentSection.document_section.title.style"
              >
                {{ currentSection.document_section.title.text }}
              </p>
              <div v-for="list in allDocumentList(project)" :key="list.id">
                <div v-for="(listItem, index) in list.document_list_item" :key="listItem.id">
                  <div
                    class="document-section m-3"
                    :class="documentSectionNotifyClass(
                      project, applied, hasDocumentUploadNotify, listItem.id
                    )"
                  >
                    <div class="row p-2">
                      <p class="col-2 col-md-1 emoji-icon mb-0">&#x1F4C4;</p>
                      <div class="col-6 col-md-8 col-lg-9">
                        <p class="mb-0 sub-section-title">
                          {{ listItem.name }}
                          <span
                            class="text-danger"
                            v-if="isDocumentRequired(listItem)"
                            notranslate
                          >
                            *
                          </span>
                        </p>
                        <p class="mb-0 description-small">{{ listItem.description }}</p>
                      </div>
                      <label
                        class="btn btn-link col-4 col-md-1"
                        v-if="!applied || requestUpload.includes(`${listItem.id}-${project.id}`)"
                        @change="createDocument(
                          $event.target.files,
                      `document-listItem-${listItem.id}-${project.id}`
                        )"
                        :class="currentSection.document_section.upload_btn.class"
                        :style="currentSection.document_section.upload_btn.style"
                      >
                        {{ currentSection.document_section.upload_btn.text }}
                        <input class="d-none" type="file" />
                      </label>
                    </div>
                    <document
                      :class="`listItem-${index}`"
                      :documentableType="'ListItem'"
                      :documentableId="listItem.id"
                      :ref="`document-listItem-${listItem.id}-${project.id}`"
                      :applied="applied"
                      :documentSection="currentSection.document_section"
                      :currentProject="project"
                      :is_requested="requestUpload.includes(`${listItem.id}-${project.id}`)"
                      @request-upload="updateUploadObj"
                      @re-upload="documentReUploaded"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="required-section m-2 pb-1" v-if="questionList.length > 0">
              <p
                class="m-3 sub-section-title pt-2"
                :class="currentSection.question_section.title.class"
                :style="currentSection.question_section.title.style"
              >
                {{ currentSection.question_section.title.text }}
              </p>
              <div v-for="list in questionList" :key="list.id" class="m-3">
                <validation-observer
                  @submit.prevent=""
                  :ref="`q-list-${list.id}`"
                >
                  <div
                    v-for="(listItem, index) in list.question_list_item"
                    :key="listItem.id"
                    :class="['pb-2', `question-list-${list.id}`]"
                  >
                    <answer
                      :class="`listItem-${index}`"
                      :commentableType="'ListItem'"
                      :commentableId="listItem.id"
                      :buildingId="currentBuilding.id"
                      :listItem="listItem"
                      :ref="`answerComponent-${listItem.id}-${project.id}`"
                      :editable="list.editable"
                      :applied="applied"
                      :currentSubSection="currentSection.question_section"
                      :hideRequestedUpdates="hideRequestedUpdates"
                      :currentProject="project"
                    />
                  </div>
                  <div
                    class="text-center"
                    v-if="!applied
                      && !answerExist(list.question_list_item, project)
                      && list.editable && isAnswerSaveAllowed"
                  >
                    <button
                      class="btn btn-outline-primary btn-sm
                        edit-btn text-center save-itemList-answer"
                      @click.once="saveAnswer(list.id, project.id)"
                      :class="currentSection.question_section.btns.class"
                      :style="currentSection.question_section.btns.style"
                    >
                      Save
                    </button>
                  </div>
                </validation-observer>
              </div>

              <div v-if="getCommentHistory(project.id).length > 0">
                <hr>
                <div class="m-2 sub-section-title pt-2 d-flex justify-content-between mr-3 ml-3">
                  <span>Action Log</span>
                  <span
                    v-b-toggle="`toggle-action-log-${currentIncentive.id}-${project.id}`"
                    class="font-weight-light egx-partner-color"
                  >
                <u class="mr-2">View All</u>
              </span>
                </div>
                <b-collapse
                  class="m-auto p-1"
                  :id="`toggle-action-log-${currentIncentive.id}-${project.id}`"
                  tag="div"
                  v-for="comment in getCommentHistory(project.id)"
                  :key="`comment-history-${comment.id}`"
                >
                  <hr class="m-3">
                  <div class="d-md-flex d-block mr-3 ml-3">
                <span class="align-content-start" notranslate>
                  {{ formatDate(comment.created_at) }}
                </span>
                    <br class="d-md-none">
                    <span class="ml-auto" v-if="comment.object_version.content !== ''">
                  <span class="text-black-50"> Updated </span>
                  <span>{{ getCommentListItem(comment).name }}</span>
                  <span class="text-black-50"> from </span>
                  <span><var item-prev-version>{{ comment.object_version.content }}</var></span>
                  <span v-if="comment.next_version && comment.next_version.content !== ''">
                    <span class="text-black-50"> to </span>
                    <span><var item-next-version>{{ comment.next_version.content }}</var></span>
                  </span>
                </span>
                    <div v-else class="ml-auto">
                      <span class="text-black-50"> Removed </span>
                      <span>{{ getCommentListItem(comment).name }}</span>
                    </div>
                  </div>
                </b-collapse>
              </div>

            </div>
          </div>
        </div>

        <div v-show="beforeSubmitError" class="alert alert-danger">
          <div class="msg-error text-danger">{{ beforeSubmitError }}</div>
        </div>
        <div class="row m-2 pt-2">
          <div
            v-show="requireConsent"
            class="form-group no-gutters col-12 constent-link"
            :class="{ 'col-lg-8': !applied }"
            v-if="!applied || isUserRequested"
          >
            <label for="consent-checkbox">
              <input id="consent-checkbox" v-model="consent" type="checkbox" name="consent">
              <span
                class="pl-2"
                :class="currentSection.consent.class"
                :style="currentSection.consent.style"
                v-html="currentSection.consent.text"
              />
            </label>
          </div>
          <div
            class="col-12 text-right justify-content-end"
            :class="{ 'col-lg-4': requireConsent }"
            v-if="!applied"
          >
            <span
              @click="sendApplication()"
              class="btn btn-outline-primary btn-lg"
              :class="currentSection.submit_btn.class"
              :style="currentSection.submit_btn.style"
            >
              {{ currentSection.submit_btn.text }}
              <b-spinner label="Spinning" v-if="clickDisabled" />
            </span>
          </div>
        </div>
        <div v-if="isUserRequested">
          <div
            class="col-12 text-right justify-content-end"
          >
            <span
              @click="sendApplication()"
              class="btn btn-outline-primary btn-lg"
              :class="currentSection.submit_btn.class"
              :style="currentSection.submit_btn.style"
            >
              Submit updates
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ObjDetailDisplayEdit from './ObjDetailDisplayEdit.vue';
import Document from './Document.vue';
import Answer from './Answer.vue';
import emptyObjectMixin from '../shared/mixins/empty-object';
import zipFormatter from '../shared/mixins/zip-formatter';
import BuildingFormFields from './BuildingFormFields.vue';
import UserFormFields from './UserFormFields.vue';
import Incentive from '../shared/mixins/incentive';

export default {
  name: 'IncentiveApplication',

  mixins: [emptyObjectMixin, zipFormatter, Incentive],

  components: {
    ObjDetailDisplayEdit,
    Document,
    Answer,
    BuildingFormFields,
    UserFormFields,
  },

  props: {
    currentIncentive: Object,
    hasDocumentUploadNotify: Boolean,
    currentProjects: Array,
    applied: Boolean,
    incentiveType: String,
  },

  data() {
    return {
      emojiIcons: {
        documentIcon: '&#x1F4C4;',
        photoIcon: '&#x1F5BC;',
      },
      consent: false,
      beforeSubmitError: null,
      projectCreated: false,
      editFields: null,
      requestUpload: [],
      hideRequestedUpdates: false,
      hideSubmitEditsButton: false,
      clickDisabled: false,
      updatedIncentive: null,
      isLoading: true,
      requestUploadCheckList: [],
      isAnswerSaveAllowed: true,
    };
  },

  mounted() {
    this.isLoading = this.useOffer;
  },

  computed: {
    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    selectedIncentive() {
      return this.updatedIncentive || this.currentIncentive;
    },

    documentList() {
      return this.selectedIncentive.attributes.document_list;
    },

    readOnlyDocumentList() {
      return this.selectedIncentive.attributes.read_only_document_list;
    },

    questionList() {
      return this.selectedIncentive.attributes.question_list;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    useOffer() {
      return this.partner.settings.energyxpert_admin_enabled;
    },

    currentSection() {
      return this.partner.settings.sections.incentive_application_popup;
    },

    requireConsent() {
      return this.partner.settings.user_consent_mandatory;
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },

    isUserRequested() {
      return (
        (this.checkUpdateRequests().length > 0 || this.requestUpload.length > 0)
        && !this.hideSubmitEditsButton
        && this.applied
      );
    },
  },

  methods: {
    allDocumentList(project) {
      if (project === undefined || this.documentForCustomerCount(project) < 1) {
        return this.documentList;
      }

      return this.readOnlyDocumentList.concat(this.documentList);
    },

    documentForCustomerCount(project) {
      if (project === undefined) {
        return 0;
      }

      return project.admin_document_upload.length;
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US').split('/').join('-');
    },

    getCommentListItem(comment) {
      const listItem = this.questionList[0].question_list_item;

      return listItem.find((item) => item.id === comment.object_version.commentable_id);
    },

    getCommentHistory(projectId = null) {
      if (!this.questionList.length > 0) return [];

      const questionListItemIds = this.questionList[0].question_list_item.map((item) => item.id);
      const { comments } = this.currentBuilding;

      return comments.filter((comment) => questionListItemIds.includes(comment.commentable_id)
        && comment.comment_history.length > 0
        && comment.project_id === projectId).map((c) => c.comment_history).flat()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },

    createDocument(fileList, ref) {
      this.$refs[ref][0].createDocument(fileList);
    },

    saveAnswer(listId, projectId = null) {
      const self = this;
      const selectors = `.question-list-${listId} .question-list-item`;
      this.isAnswerSaveAllowed = false;

      document.querySelectorAll(selectors).forEach((listItem) => {
        let listItemValue = listItem.value;

        if (listItem.classList.contains('vue-js-switch')) {
          listItemValue = listItem.children[0].checked.toString();
        } else if (listItem.classList.contains('multi-select')) {
          listItemValue = listItemValue.split(',');
        }

        if (listItemValue !== undefined) {
          const comment = {
            commentable_type: 'ListItem',
            commentable_id: listItem.id,
            content: listItemValue,
            building_id: self.currentBuilding.id,
            content_not_required: true,
          };

          const data = {
            comment,
          };

          self.$store.dispatch('comments/post', data).then(() => {
            this.isLoading = true;
            self.$store.dispatch('buildings/get');
            let component = `answerComponent-${listItem.id}`;
            if (projectId) {
              component += `-${projectId}`;
            }
            self.$refs[component][0].getComment();
          }).catch((error) => {
            console.log(error);
          }).finally(() => {
            this.isLoading = false;
          });
        }
      });
    },

    answerExist(listItems, project = undefined) {
      const self = this;
      // eslint-disable-next-line prefer-destructuring
      const comments = self.currentBuilding.comments;
      const listItemsCount = listItems.length;
      const questionAnswered = {};
      const projectId = project === undefined ? null : project.id;

      if (!comments || comments.length === 0) return false;

      // eslint-disable-next-line no-restricted-syntax
      for (const listItem of listItems) {
        // eslint-disable-next-line no-restricted-syntax
        for (const comment of comments) {
          if (comment.commentable_id === listItem.id
            && comment.commentable_type === 'ListItem'
            && comment.project_id === projectId
          ) {
            questionAnswered[listItem.id] = true;
          }
        }
      }

      return (listItemsCount === Object.keys(questionAnswered).length);
    },

    sendApplication() {
      if (this.clickDisabled) {
        return false;
      }

      const self = this;
      let isValidated = true;
      let stillEditingAnswers = 0;
      let uneditedRequest = 0;
      const promises = [];

      this.questionList.forEach((list) => {
        const validateSelector = `q-list-${list.id}`;

        stillEditingAnswers
          += self.$refs[validateSelector][0].$children.filter((c) => c.showInput === true).length;

        promises.push(self.$refs[validateSelector][0].validate().then((valid) => {
          if (!valid) {
            isValidated = false;
            console.log(`Item- ${list.id} - Vee Validate Errors`);
          }
        }));
      });

      if (this.applied) {
        this.checkUpdateRequests().forEach((request) => {
          const answerComponent = `answerComponent-${request.commentable_id}-${request.project_id}`;
          const editCounter = self.$refs[answerComponent]
            .reduce((sum, object) => sum + object.editCounter, 0);

          if (editCounter < 1) uneditedRequest += 1;
        });
      }

      Promise.all(promises)
        .then(() => {
          if (!isValidated) {
            this.beforeSubmitError = 'You must complete the mandatory fields and upload '
              + 'the required documents to submit your application.';
          } else if (stillEditingAnswers > 0) {
            this.beforeSubmitError = 'Please update all answers.';
          } else if (document.querySelector('button.save-itemList-answer') !== null) {
            this.beforeSubmitError = 'Please save answers above.';
          } else if (!this.consent && self.requireConsent) {
            this.beforeSubmitError = 'You must agree to the terms and conditions to apply.';
          } else if (!this.checkDocument()) {
            this.beforeSubmitError = 'Please upload required documents.';
          } else if (!this.checkQuestion()) {
            this.beforeSubmitError = 'Please answer required questions.';
          } else if (this.requestUploadCheckList.length > 0) {
            this.beforeSubmitError = 'Please re-upload for requested document(s)';
          } else if (uneditedRequest !== 0) {
            this.beforeSubmitError = 'Please edit the requested fields';
          } else {
            this.beforeSubmitError = null;

            // eslint-disable-next-line no-unused-expressions
            this.applied ? this.updateApplication() : this.submitApplication();
          }
        });

      return this.beforeSubmitError;
    },

    submitApplication() {
      // Disable Click
      this.clickDisabled = true;

      const payload = {
        id: this.currentIncentive.id,
        useOffer: this.useOffer,
      };

      this.$store.dispatch('buildings/setProject', payload)
        .then(() => {
          this.$notify({
            group: 'notice',
            text: 'Application submitted.',
          });

          this.projectCreated = true;
          // The reason why here it needs to fetch all buildings because when checking user
          // availability it will check for allBuilding rebate projects
          this.$store.dispatch('buildings/get');
          this.$store.dispatch('buildings/getAll');
          this.consent = false;
          const modalRef = this.$refs[`incentive-application-${this.incentiveType}-${this.currentIncentive.id}`];
          if (modalRef) {
            modalRef.hide();
          }
          this.isAnswerSaveAllowed = true;
        })
        .catch((error) => {
          const errorMsg = error.response ? error.response.data.errors[0].title : error;
          this.beforeSubmitError = errorMsg;
          this.$notify({
            group: 'notice',
            text: errorMsg,
          });
        })
        .finally(() => {
          this.clickDisabled = false;
        });
    },

    checkUpdateRequests() {
      if (!this.questionList.length > 0) return [];

      const questionListItemIds = this.questionList[0].question_list_item.map((item) => item.id);
      const { comments } = this.currentBuilding;

      return comments.filter((comment) => questionListItemIds.includes(comment.commentable_id)
        && comment.requested_comments.length > 0);
    },

    requestUploadComments() {
      const { comments } = this.currentBuilding;

      return comments.filter(
        (comment) => this.requestUpload.includes(`${comment.commentable_id}-${comment.project_id}`)
          && comment.requested_comments.length > 0,
      );
    },

    async updateApplication() {
      const questionDocumentComments = this.checkUpdateRequests().concat(
        this.requestUploadComments(),
      );

      if (document.getElementsByClassName('not-collapsed').length > 0) {
        this.$root.$emit('bv::toggle::collapse', `toggle-action-log-${this.currentIncentive.id}`);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const comment of questionDocumentComments) {
        const commentParams = { comment };

        if (comment.requested_comments && comment.requested_comments.length > 0) {
          commentParams.comment.requested_comment_id = comment.requested_comments[0].id;
        }

        if (this.requestUpload.includes(`${comment.commentable_id}-${comment.project_id}`)) {
          commentParams.comment.is_upload_request = true;
        }

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('comments/put', commentParams)
          .then((response) => {
            console.log(response);
            this.hideRequestedUpdates = true;
            this.hideSubmitEditsButton = true;
            this.$store.dispatch(
              'buildings/updateProjectRequestedEdit',
              { id: comment.project_id },
            );
          })
          .catch((error) => {
            this.beforeSubmitError = error.response?.data?.errors?.[0]?.title || 'Error Updating!';
            this.$notify({
              group: 'notice',
              text: this.beforeSubmitError,
            });
          })
          .finally(() => {
            this.$store.dispatch('buildings/get');
          });
      }

      this.requestUpload = [];
    },

    resetModal() {
      this.beforeSubmitError = null;
      this.hideEditFields();
      if (this.isLoading) {
        this.$store.dispatch('offers/get', { id: this.currentIncentive.id })
          .then((incentive) => {
            this.updatedIncentive = incentive;
            this.isLoading = false;
          });
      }
    },

    onModalClose() {
      if (this.hasDocumentUploadNotify) {
        this.currentProjects.forEach(async (project) => {
          if (project.document_upload_notify_list !== null
            || project.document_upload_notify_list !== []) {
            await this.resetAdminUploadDocumentNotify(project.id);
          }
        });
      }
    },

    checkDocument() {
      const self = this;
      const requiredDocuments = self.selectedIncentive.attributes.required_document;

      if (requiredDocuments.length === 0) return true;
      const currentProjectIds = this.currentProjects.map((obj) => obj.id);
      if (!this.applied) {
        currentProjectIds.push(null);
      }

      return self.$store.getters['documents/checkDocument'](requiredDocuments, currentProjectIds);
    },

    isDocumentRequired(document) {
      const requiredDocuments = this.selectedIncentive.attributes.required_document;

      return requiredDocuments.some((reqDoc) => reqDoc.required_id === document.id);
    },

    checkQuestion() {
      const self = this;
      const requiredQuestions = self.selectedIncentive.attributes.required_question;

      if (requiredQuestions.length === 0) return true;

      // eslint-disable-next-line no-restricted-syntax
      for (const requiredQuestion of requiredQuestions) {
        if (!self.currentBuilding.comments
          .find((comment) => comment.commentable_id === requiredQuestion.required_id
            && comment.commentable_type === 'ListItem')) return false;
      }

      return true;
    },

    displayEditField(e) {
      if (this.editFields === e) {
        this.hideEditFields();
      } else {
        this.editFields = e;
      }
    },

    hideEditFields() {
      this.editFields = null;
    },

    checkIfNotSelected(currentField) {
      return ((this.editFields !== null && currentField !== this.editFields)
        ? 'not-selected' : '');
    },

    updateUploadObj(commentableId, isRequested, projectId) {
      if (isRequested) {
        const requested = `${commentableId}-${projectId}`;
        if (this.requestUpload.indexOf(requested) === -1) {
          this.requestUpload.push(requested);
          this.requestUploadCheckList.push(requested);
        }
      }
    },

    documentReUploaded(commentableId, projectId) {
      const requested = `${commentableId}-${projectId}`;
      const index = this.requestUpload.indexOf(requested);
      if (index !== -1) {
        this.requestUploadCheckList.splice(index);
      }
    },
  },

  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/components/incentive_application';
</style>
