<template>
  <div class="container sign-up">
    <template v-if="!hideUserPassLogin">
      <form class="sign-up-form" @submit.prevent="signIn()">
        <div
          :style="this.virtualAuditSignInLogo.style"
          v-if="this.virtualAuditSignInLogo && this.virtualAuditSignInLogo.allowed"
        >
          <img
            :src="virtualAuditSignInLogo.url"
            :width="virtualAuditSignInLogo.img && virtualAuditSignInLogo.img.width"
            :height="virtualAuditSignInLogo.img && virtualAuditSignInLogo.img.height"
            alt="logo"
            class="img-fluid"
          >
        </div>
        <div v-show="page1">
          <h1
            class="text-center"
            :class="settings.title.class"
            :style="settings.title.style"
          >
            {{ settings.title.text }}
          </h1>
          <h4
            class="text-center"
            :class="settings.subtitle.class"
            :style="settings.subtitle.style"
            v-html="breakLines(settings.subtitle.text)"
          >
          </h4>
          <h4
            class="text-center mt-5"
            :class="settings.description.class"
            :style="settings.description.style"
            v-html="breakLines(settings.description.text)"
            v-if="settings.description"
          />
          <s-s-o-button-section
            position="top"
            :loginUrl="partner.sso.login_url"
            :settingsSSOButton="settings.sso_button"
            v-if="ssoAllowed && settings.sso_button && settings.sso_button.postion_top"
          />
          <div class="input-group-lg mt-5">
            <validation-observer
              tag="form"
              @submit.prevent="signIn()"
              ref="userSignIn"
            >
              <form-field
                :formFields="settings.fields"
                :show="true"
                :model="data.credentials"
                :id-prefix="idPrefix"
              />
              <div
                class="text-center"
                v-if="this.soapUserAuthentication && this.soapUserAuthentication.allowed">
                <template v-if="!this.partner.sso.allowed || !this.partner.sso.issuer_login_only">
                  <a
                    target="_blank"
                    :class="settings.sign_up_link.class"
                    :style="settings.sign_up_link.style"
                    :href="soapUserAuthentication.create_profile_url"
                  >
                    {{ settings.sign_up_link.text }}
                  </a>

                  <span>&nbsp;|&nbsp;</span>
                </template>

                <a
                  target="_blank"
                  :class="settings.forgot_password_link.class"
                  :style="settings.forgot_password_link.style"
                  :href="soapUserAuthentication.forgot_password_url"
                >
                  {{ settings.forgot_password_link.text }}
                </a>
              </div>
              <div class="text-center" v-else>
                <template v-if="!this.partner.sso.allowed || !this.partner.sso.issuer_login_only">
                  <router-link
                    :class="settings.sign_up_link.class"
                    :style="settings.sign_up_link.style"
                    :to="settings.sign_up_link.path"
                  >
                    {{ settings.sign_up_link.text }}
                  </router-link>

                  <span>&nbsp;|&nbsp;</span>
                </template>

                <router-link
                  :class="settings.forgot_password_link.class"
                  :style="settings.forgot_password_link.style"
                  :to="settings.forgot_password_link.path"
                >
                  {{ settings.forgot_password_link.text }}
                </router-link>
              </div>

              <button
                type="submit"
                class="btn btn-lg d-flex ml-auto mr-auto mt-5"
                :class="settings.button.class"
                :style="settings.button.style"
              >
                {{ settings.button.text }}
              </button>
              <h4
                class="text-center mt-5"
                :class="settings.description2.class"
                :style="settings.description2.style"
                v-html="breakLines(settings.description2.text)"
                v-if="settings.description2"
              />
            </validation-observer>
          </div>
        </div>
      </form>
    </template>
    <template v-if="partner.sso.allowed">
      <div v-if="partner.sso.hide_user_pass_login && settings.sso_description" class="mb-5">
        <h1
          class="text-center"
          :class="settings.sso_description.title.class"
          :style="settings.sso_description.title.style"
        >
          {{ settings.sso_description.title.text }}
        </h1>
        <h4
          class="text-center"
          :class="settings.sso_description.description.class"
          :style="settings.sso_description.description.style"
        >
          {{ settings.sso_description.description.text }}
        </h4>
      </div>
      <s-s-o-button-section
        position="bottom"
        :loginUrl="partner.sso.login_url"
        :settingsSSOButton="settings.sso_button"
        :hideSectionDivider="partner.sso.hide_user_pass_login"
        v-if="ssoAllowed && settings.sso_button && !settings.sso_button.postion_top"
      />
    </template>
    <notice-section :notice-details="settings.notice_section" />
  </div>
</template>

<script>
import initHelpHero from 'helphero';
import FormField from '../../components/form/FormField.vue';
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';
import NoticeSection from '../../components/NoticeSection.vue';
import SSOButtonSection from '../../components/SSOButtonSection.vue';

export default {
  name: 'SignIn',

  components: {
    FormField,
    NoticeSection,
    SSOButtonSection,
  },

  data() {
    return {
      page1: true,
      idPrefix: 'sign-in-page',

      data: {
        credentials: {
          email: '',
          password: '',
        },
      },
    };
  },

  mounted() {
    const { userExists } = this.$route.query;

    if (userExists) {
      this.$notify({
        group: 'notice2',
        text: this.userExistNotification,
      });
    }
  },

  beforeDestroy() {
    this.$notify({
      group: 'notice2',
      clean: true,
    });
  },

  methods: {
    signIn() {
      this.$refs.userSignIn.validate()
        .then((isValid) => {
          if (isValid) {
            this.$store.dispatch('auth/signIn', this.data.credentials)
              .then(() => {
                const hlp = initHelpHero(`${this.partner.helphero_app_id}`);
                hlp.identify(this.currentUser.id);

                const routerPush = (this.isRedirectPresent) ? this.redirectRouteObject() : { name: 'buildings-list' };
                router.push(routerPush);
              })
              .catch((error) => {
                this.$notify({
                  group: 'notice',
                  text: error.response.data.errors[0].title,
                });
              });
          } else {
            this.$notify({
              group: 'notice',
              text: 'The email or password are incorrect. Try again.',
            });
          }
        });
    },

    redirectRouteObject() {
      return { path: (this.$route.query.redirect || 'buildings-list') };
    },
    breakLines(text) {
      return text.replace(/\n/g, '<br>');
    },
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.sign_in.sign_in;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    ssoAllowed() {
      return this.partner.sso.allowed
        && !this.partner.sso.issuer_login_only;
    },

    isRedirectPresent() {
      return this.$route.query.redirect !== undefined;
    },

    hideUserPassLogin() {
      return this.partner.sso.hide_user_pass_login;
    },

    userExistNotification() {
      return this.partner.sso.user_exist_notification || 'User account already exist!';
    },
    soapUserAuthentication() {
      return this.partner.settings.soap_user_authentication;
    },
    virtualAuditSignInLogo() {
      return this.partner.settings.virtual_audit_sign_in_logo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/auth/sign_in.scss';
</style>
