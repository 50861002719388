<template>
  <div class="row" v-if="show">
    <div
      class="form-group input-group-lg"
      :class="columnMdSize(field.column_size)"
      v-for="(field, index) in formFields"
      :key="index"
      v-show="!field.hidden"
    >
<!-- <form-label-field :id-prefix="idPrefix" :field="field">{{ field.label }}</form-label-field>-->

      <form-input-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-if="isInputType(field.type)"
      />

      <form-select-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-else-if="field.type === 'select'"
      />

      <form-fieldset-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-else-if="field.type === 'radio'"
      />

<!--      <form-text-area-field :id-prefix="idPrefix" :field="field" :model="model"-->
<!--                            :store-configuration="storeConfiguration"-->
<!--                            v-else-if="field.type === 'text-area'" />-->

<!--      <form-image-upload-field :id-prefix="idPrefix" :field="field" :model="model"-->
<!--                               :store-configuration="storeConfiguration"-->
<!--                               v-else-if="field.type === 'image-upload'"/>-->
    </div>
  </div>
</template>

<script>

// import FormLabelField from './LabelField.vue';
import FormInputField from './InputField.vue';
import FormSelectField from './SelectField.vue';
import FormFieldsetField from './FieldsetField.vue';
// import FormImageUploadField from './ImageUploadField.vue';
// import FormTextAreaField from './TextAreaField.vue';

export default {
  name: 'FormField',

  props: {
    formFields: Array,
    show: Boolean,
    model: Object,
    idPrefix: String,
    isCopyAddress: Boolean,

    // storeConfiguration: {
    //   type: Object,
    //   required: true,
    // },
  },

  components: {
    // FormLabelField,
    FormInputField,
    FormSelectField,
    FormFieldsetField,
    // FormImageUploadField,
    // FormTextAreaField,
  },

  methods: {
    isInputType(type) {
      const validTypes = ['text', 'number', 'email', 'password'];

      return validTypes.includes(type);
    },

    columnMdSize(columnSize) {
      if (columnSize === '6') {
        return 'col-md-6';
      }

      return 'col-md-12';
    },
  },

};

</script>
