<template>
  <div class="container sign-up">
    <form class="sign-up-form" @submit.prevent="signUp()">
      <validation-observer ref="userCreate">
        <div v-show="page1">
          <h1
            class="text-center"

            :class="signUpFields.attributes.title.class"
            :style="signUpFields.attributes.title.style"
          >
            {{ signUpFields.attributes.title.text }}
          </h1>
          <h4
            class="text-center"
            :class="signUpFields.attributes.subtitle.class"
            :style="signUpFields.attributes.subtitle.style"
          >
            {{ signUpFields.attributes.subtitle.text }}
          </h4>
          <s-s-o-button-section
            position="top"
            :loginUrl="partner.sso.login_url"
            :settingsSSOButton="signUpFields.attributes.sso_button"
            :hideSectionDivider="ssoIssuerLoginOnly"
            v-if="
              partner.sso.allowed &&
              signUpFields.attributes.sso_button &&
              signUpFields.attributes.sso_button.postion_top
            "
          />
          <div class="input-group-lg mt-5">
            <form-field
              :formFields="signUpFields.requiredFields"
              :show="true"
              :model="data.registerUser"
              :id-prefix="idPrefix"
              v-if="!ssoIssuerLoginOnly"
            />
            <div class="text-center">
              <router-link
                :class="signUpFields.attributes.sign_in_link.class"
                :style="signUpFields.attributes.sign_in_link.style"
                :to="signUpFields.attributes.sign_in_link.path"
              >
                {{ signUpFields.attributes.sign_in_link.text }}
              </router-link>
            </div>
            <button
              type="submit"
              class="btn btn-lg d-flex ml-auto mr-auto mt-5"
              :class="signUpFields.attributes.button.class"
              :style="signUpFields.attributes.button.style"
              v-if="!ssoIssuerLoginOnly"
            >
              {{ signUpFields.attributes.button.text }}
            </button>
          </div>
        </div>
      </validation-observer>

      <div v-show="!page1">
        <validation-observer
          tag="form"
          @submit.prevent="updateUser()"
          ref="userUpdate"
        >
          <h1
            class="text-center"
            :class="signUpFields.attributes.title2.class"
            :style="signUpFields.attributes.title2.style"
          >
            {{ signUpFields.attributes.title2.text }}
          </h1>
          <h4
            class="text-center"
            :class="signUpFields.attributes.subtitle2.class"
            :style="signUpFields.attributes.subtitle2.style"
          >
            {{ signUpFields.attributes.subtitle2.text }}
          </h4>
          <div class="form-row mt-5">
            <form-field
              :formFields="signUpFields.optionalFields"
              :show="true"
              :model="data.updateUser"
              :id-prefix="idPrefix"
            />
          </div>
          <div class="form-check col-12 pr-0" v-show="signUpFields.consent.show">
            <input
              class="form-check-input" type="checkbox" value=""
              v-model="data.updateUser.consent"
              id="user-consent"
            >
            <label
              class="form-check-label"
              for="user-consent"
              :class="signUpFields.consent.class"
              :style="signUpFields.consent.style"
              v-html="signUpFields.consent.text"
            />
          </div>
          <div class="form-check col-12 pr-0" v-show="signUpFields.marketing.show">
            <input
              class="form-check-input" type="checkbox" value=""
              v-model="data.updateUser.subscribed"
              id="user-subscribed"
            >
            <label
              class="form-check-label"
              for="user-subscribed"
              :class="signUpFields.marketing.class"
              :style="signUpFields.marketing.style"
              v-html="signUpFields.marketing.text"
            />
          </div>
          <button
            type="submit"
            class="btn btn-lg d-flex ml-auto mr-auto mt-5"
            :class="signUpFields.attributes.button2.class"
            :style="signUpFields.attributes.button2.style"
          >
            {{ signUpFields.attributes.button2.text }}
          </button>
        </validation-observer>
      </div>
    </form>
    <template v-if="partner.sso.allowed && page1">
      <s-s-o-button-section
        position="bottom"
        :loginUrl="partner.sso.login_url"
        :settingsSSOButton="signUpFields.attributes.sso_button"
        :hideSectionDivider="ssoIssuerLoginOnly"
        v-if="
          signUpFields.attributes.sso_button &&
          !signUpFields.attributes.sso_button.postion_top
        "
      />
    </template>
    <notice-section :notice-details="signUpFields.notice_section" />
  </div>
</template>

<script>
import initHelpHero from 'helphero';
import FormField from '../../components/form/FormField.vue';
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';
import NoticeSection from '../../components/NoticeSection.vue';
import SSOButtonSection from '../../components/SSOButtonSection.vue';

export default {
  name: 'SignUp',

  components: {
    FormField,
    NoticeSection,
    SSOButtonSection,
  },

  mounted() {
    this.data.updateUser.consent = this.signUpFields?.consent?.checked;
    this.data.updateUser.subscribed = this.signUpFields?.marketing?.checked;
  },

  data() {
    return {
      page1: true,
      idPrefix: 'sign-up-page',

      data: {
        registerUser: {
          name: 'New user',
        },

        updateUser: {
          consent: false,
          subscribed: false,
          community: '',
          country: '',
          region: '',
        },
      },
    };
  },

  methods: {
    nextPage() {
      this.page1 = !this.page1;
    },

    signUp() {
      this.$refs.userCreate.validate()
        .then((isValid) => {
          if (isValid) {
            this.$store.dispatch('users/post', this.data.registerUser)
              .then(() => {
                const hlp = initHelpHero(`${this.partner.helphero_app_id}`);
                hlp.identify(this.currentUser.id);

                this.nextPage();
              })
              .catch((error) => {
                this.$notify({
                  group: 'notice',
                  text: error.response.data.errors[0].title,
                });
              });
          } else {
            this.$notify({
              group: 'notice',
              text: 'Fill in all required fields.',
            });
          }
        });
    },

    updateUser() {
      if (!this.data.updateUser.consent) {
        this.$notify({
          group: 'notice',
          text: 'You must agree to the terms and conditions to proceed.',
        });
      } else {
        this.$refs.userUpdate.validate()
          .then((isValid) => {
            if (isValid) {
              this.$store.dispatch('users/put', this.data.updateUser)
                .then(() => router.push({ name: 'buildings-list' }))
                .catch((error) => {
                  this.$notify({
                    group: 'notice',
                    text: error.response.data.errors[0].title,
                  });
                });
            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      }
    },
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    signUpFields() {
      return this.partner.settings.sections.sign_up;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    ssoBtnAllow() {
      return this.partner.sso.allowed && this.partner.sso.login_url;
    },

    ssoIssuerLoginOnly() {
      return this.partner.sso.allowed && this.partner.sso.issuer_login_only;
    },
  },
};
</script>
